export default  {
  data: [
    {
      title: "performance",
      value: "",
      model: "performance",
      type: "text",
    },
    {
      title: "offGrid",
      value: "",
      model: "offGrid",
      type: "checkbox",
    },
    {
      title: "installTime",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "tankSize",
      value: "",
      model: "tankSize",
      type: "text",
    },
  ]
}