import Axios from "@/misc/axios";
import TenantConfigHandler from "../../misc/TenantConfigHandler";
import toast from "@/misc/toast.js";

export default {
  setup: async (state, optionType) => {
    Axios.get(
      TenantConfigHandler.getVariable("ADMINV2_API_URL") +
        "/rem/option/" +
        optionType +
        "/option-values",
      {
        onTokenRefreshed: () => {
          this.setup();
        },
      }
    ).then(function (resp) {
      if (resp && resp.data) {
        //console.log(resp.data);
        state.value.data = resp.data;

        if (
          state.value.data.length > 0 &&
          state.value.data[0].parentDto != null
        ) {
          state.value.parentType = state.value.data[0].parentDto.type;
          Axios.get(
            TenantConfigHandler.getVariable("ADMINV2_API_URL") +
              "/rem/option/" +
              state.value.data[0].parentDto.type +
              "/option-values",
            {
              onTokenRefreshed: () => {
                this.setup();
              },
            }
          ).then(function (respP) {
            if (respP && respP.data) {
              respP.data.forEach((e) => {
                state.value.parentOptions.push({
                  label: e.value,
                  value: e.id,
                });
              });
            }
          });
        }

        state.value.data.forEach((item, i) => {
          item.openPanel = false;
          item.index = i+1;
        });
      }
    });
  },

  fetchTranslations: async (index, state) => {
    Axios.get(
      TenantConfigHandler.getVariable("ADMINV2_API_URL") +
        "/rem/option/option-values/" +
        state.value.data[index].id +
        "/translations",
      {
        onTokenRefreshed: () => {
          this.fetchTranslations();
        },
      }
    ).then(function (translations) {
      if (translations && translations.data) {
        state.value.data[index].translations = translations.data;
        state.value.data[index].newValue = "";
        state.value.data[index].newLanguage = "";
      }
    });
  },

  saveOptionValue: async (index, state, optionType, t) => {
    const hermes = {
      id: state.value.data[index].id,
      type: state.value.data[index].type,
      value: state.value.data[index].value,
      parentDto:
        state.value.data[index].parentDto == null
          ? null
          : {
              id: state.value.data[index].parentDto.id,
            },
      order: index,
      deleted: false,
    };

    if(state.value.parentOptions.length > 0 && (hermes.parentDto == null || hermes.parentDto.id == "")){
      toast.error(t("message.missingFormFields"));
      return;
    }

    Axios.put(
      TenantConfigHandler.getVariable("ADMINV2_API_URL") +
        `/rem/option/${optionType}/option-values/${state.value.data[index].id}`,
      hermes,
      {
        onTokenRefreshed: () => {
          this.saveOptionValue();
        },
      }
    ).then(function (response) {
      if (response && response.status == 200) {
        toast.success(t("message.successfulSave"));
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },

  createOptionValue: async (state, optionType, t) => {
    const hermes = {
      id: null,
      type: optionType,
      value: state.value.newOptionValue.value,
      parentDto:
      state.value.newOptionValue.parent == null
          ? null
          : {
              id: state.value.newOptionValue.parent,
            },
      order: state.value.data.length,
      deleted: false,
    };

    if(state.value.parentOptions.length > 0 && (hermes.parentDto == null || hermes.parentDto.id == "")){
      toast.error(t("message.missingFormFields"));
      return;
    }

    Axios.post(
      TenantConfigHandler.getVariable("ADMINV2_API_URL") +
        `/rem/option/${optionType}/option-values`,
      hermes,
      {
        onTokenRefreshed: () => {
          this.saveOptionValue();
        },
      }
    ).then(function (response) {
      if (response && response.status == 200) {
        toast.success(t("message.successfulSave"));
        hermes.id = response.data.id;
        state.value.data.push(hermes)
        state.value.newOptionValue.value = "";
        state.value.newOptionValue.parent = null
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },

  deleteOptionValue: async (state, index, optionType, t) => {
    const hermes = {
      optionValueAdminOrderDtos: [],
    };

    const id = state.value.data[index].id
    state.value.data.splice(index, 1);
    

    state.value.data.forEach((e) => {
      hermes.optionValueAdminOrderDtos.push({
        id: e.id,
        order: index,
        deleted: false,
      });
    });

    Axios.put(
      TenantConfigHandler.getVariable("ADMINV2_API_URL") +
        `/rem/option/${optionType}/option-values/${id}/delete`,
      hermes,
      {
        onTokenRefreshed: () => {
          this.deleteOptionValue();
        },
      }
    ).then(function (ovresp) {
        if(ovresp && (ovresp.status == 200 || ovresp.status == 204)){
            toast.success(t("message.deleteSucessful"));
        }else{
            toast.error(t("message.deleteNotSuccessful"));
        }
      console.log(ovresp);
    });
  },

  saveOrder: async (state, optionType, t) => {
    const hermes = {
      optionValueAdminOrderDtos: [],
    };

    state.value.data.forEach((e, index) => {
      hermes.optionValueAdminOrderDtos.push({
        id: e.id,
        order: index,
        deleted: false,
      });
    });

    Axios.put(
      TenantConfigHandler.getVariable("ADMINV2_API_URL") +
        `/rem/option/${optionType}/option-values/order`,
      hermes,
      {
        onTokenRefreshed: () => {
          this.saveOrder();
        },
      }
    ).then(function (resp) {
      if (resp && resp.status == 200) {
        toast.success(t("message.successfulSave"));
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },

  addTranslationElement(index, state, t) {
    if (state.value.data[index].newValue == "") {
      toast.error(t("message.optionValueTokenEmpty"));
      return;
    }
    if (
      state.value.data[index].newLanguage != "" &&
      state.value.data[index].newValue != ""
    ) {
      let lever = true;

      state.value.data[index].translations.forEach((tr) => {
        if (tr.languageCode == state.value.data[index].newLanguage) {
          lever = false;
        }
      });

      if (lever == false) {
        toast.error(t("message.optionValueDuplicateTr"));
        return;
      }

      Axios.post(
        TenantConfigHandler.getVariable("ADMINV2_API_URL") +
          "/rem/option/option-values/" +
          state.value.data[index].id +
          "/translations",
        {
          languageCode: state.value.data[index].newLanguage,
          translatedValue: state.value.data[index].newValue,
        },
        {
          onTokenRefreshed: () => {
            this.asyncTranslationSave(index, state, t);
          },
        }
      ).then((response) => {
        if (response && response.status == 200) {
          toast.success(t("message.successfulSave"));
          state.value.data[index].translations.push({
            languageCode: state.value.data[index].newLanguage,
            translatedValue: state.value.data[index].newValue,
          });
          state.value.data[index].newLanguage = "";
          state.value.data[index].newValue = "";
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },

  saveTranslationElement(index, languageCode, state, t) {
    let hermes = null;
    state.value.data[index].forEach((e) => {
      if (e.languageCode == languageCode) {
        hermes = e;
      }
    });
    Axios.patch(
      TenantConfigHandler.getVariable("ADMINV2_API_URL") +
        `rem/option/option-value-translations/`,
      hermes,
      {
        onTokenRefreshed: () => {
          this.asyncTranslationSave(index, state, t);
        },
      }
    ).then((response) => {
      if (response && response.status == 200) {
        toast.success(t("message.successfulSave"));
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },
};
