let uniqueField = 'partnersGrid';
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";

export default (state, t, buildingId, partnerMainTypeMapping, partnerSubTypeMapping) => {
  return {
    endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/building/${buildingId}/all-related-partner`,
    idkey: "id",
    defaultOrderKey: 'id',
    defaultOrderDirection: 'desc',
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: 'server', // server or local/null
      serverGridKey: 'worksGridColumns'
    },
    xlsxExport: {
      active: true
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [{
        key: '30sec',
        refreshInterval: 30,
        title: t('message.rvg-automatic-list-refresh-30-sec'),
        default: true
      }, {
        key: '1min',
        refreshInterval: 60,
        title: t('message.rvg-automatic-list-refresh-1-min'),
      }, {
        key: '5min',
        refreshInterval: 300,
        title: t('message.rvg-automatic-list-refresh-5-min'),
      }]
    },
    mapping: {
      "$operations": {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: '50px',
        formatter: {
          type: 'Operations',
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: '',
                tooltip: t('message.edit'),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: 'btn btn-secondary btn-sm hover:!opacity-80 btn-circle',
                testValueAttribute: 'partnerEditButton',
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  state.value.partnerEdit = true;
                  state.value.partnerId = record.id;
                  state.value.partnerData.forEach((e) => {
                    if (e.type == "option" ) {
                      e.value = record[e.model + "Id"];
                      e.valueLabel = record[e.model + "Value"];
                    } else {
                      e.value = record[e.model];
                    }
                  });
                  state.value.partnerData[3].value = state.value.partnerData[4].value != "" ? 'true' : 'false'
                  state.value.isPerson = state.value.partnerData[4].value != "" ? true : false
                  store.commit("setRemFormEdit", false);
                  state.value.tab = 14;
                }
              })

              return buttons;
            }
          }
        }
      },
      "id": {
        title: "ID",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Number'
        },
        exportable: false,
        orderable: true,
      },
      "name": {
        title: t("message.name"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.name ? record.name : 'N/A'
        }
      },
      "partnerMainTypeValue": {
        title: t("message.mainType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record.partnerMainTypeValue ? record.partnerMainTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: partnerMainTypeMapping,
        },             
      },
      "partnerSubTypeValue": {
        title: t("message.subType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record.partnerSubTypeValue ? record.partnerSubTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: partnerSubTypeMapping,
        },             
      },
      "taxId": {
        title: t("message.taxId"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.taxId ? record.taxId : 'N/A'
        }
      },
      "taxNumber": {
        title: t("message.taxNumber"),
        visible: true,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Number'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.taxNumber ? record.taxNumber : 'N/A'
        }
      },
    }
  }
}
