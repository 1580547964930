
import moment from 'moment';

export default {
  possibleOperations() {
    return {
      'eq': 'ezen a napon:',
      'be': 'ebben az intervallumban:'
    }
  },
  getTextual(operation, currentValue, convertUnixToReadable) {
    var possibleOperations = this.possibleOperations();

    if(operation == "be") {
      const[start, end] = currentValue.split(",");
      const newCurrentValue = {
        start: start,
        end: end
      }

      if(convertUnixToReadable) {
        newCurrentValue.start = moment(start).format("YYYY MMM. DD.")
        newCurrentValue.end = moment(end).format("YYYY MMM. DD.")
      }

      return '%%fieldname%% ' + possibleOperations[operation] + ' ' + newCurrentValue.start + " - " + newCurrentValue.end
    }
    else {
      if(convertUnixToReadable) {
        currentValue = moment(currentValue).format("YYYY MMM. DD.")
      }
      return '%%fieldname%% ' + possibleOperations[operation] + ' ' + currentValue
    }
  },
  isMatch(recordValue, operation, matchingValue) {
    const momentFormat = "YYYY-MM-DD"
    switch(operation) {
      case 'eq':
        return moment(recordValue).format(momentFormat) == moment(matchingValue).format(momentFormat);
      case 'be':
        return moment(recordValue).format(momentFormat) < moment(matchingValue.end).format(momentFormat) && moment(recordValue).format(momentFormat) >= moment(matchingValue.start).format(momentFormat);
    }
  }
}