<template>
    <div  class="dropdown w-full">
                <select v-model="inputValue" class="input input-sm input-bordered w-full" @focusout="focus = false">
                    <option selected value="">{{ $t('message.select') }}</option>
                    <option value="null">{{ $t('message.empty') }}</option>
                    <option value="true">{{ $t('message.yes') }}</option>
                    <option value="false">{{ $t('message.no') }}</option>
                </select>
            </div>
</template>

<script setup>
import {defineModel } from 'vue';

defineProps(['type']);

const inputValue = defineModel('inputValue')
const focus = defineModel('focus')
</script>

<style scoped>

.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dropdown::before {
    position: absolute;
    font-size: 12px;
    content: " \25BC";
    top: 7px;
    right: -8px;
    height: 20px;
    width: 28px;
    pointer-events: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
</style>