<template>
  <div class="w-full border border-gray-700 rounded-lg">
    <v-select
      :options="filteredSuggestions"
      label="value"
      v-model="state.selectedOption"
      :placeholder="placeholder"
    >
    <!-- eslint-disable -->
    <template v-slot:no-options>
     {{$t("message.noMatchingOptions")}}
    </template>
    <!-- eslint-enable -->
  </v-select>
  </div>
</template>

<script setup>
import "vue-select/dist/vue-select.css";
import axios from "@/misc/axios.js";
import { onMounted, reactive, watch, computed } from "vue";
import store from "@/misc/vuex-store.js";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

import TenantConfigHandler from '@/misc/TenantConfigHandler';

const props = defineProps(["modelValue", "type"]);
const emits = defineEmits(["update:modelValue"]);

const state = reactive({
  selectedOption: props.modelValue || null,
  suggestions: [],
  selectedPartnerMainType: null,
});

// const getOptionTypes = async () => {
//   const uri = TenantConfigHandler.getVariable("ADMINV2_API_URL") + "/option/option-types"
//   try {
//     const response = await axios.get(uri)
//     console.log(response);
//   } catch (error) {
//     console.log(error);
//   }
// }

const placeholder = computed(()=>{
  return t('message.choose')
})

const getOptions = async () => {
  const uri =
    TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/option/options/${props.type}`;
  try {
    const response = await axios.get(uri);
    state.suggestions = response.data;

    if (props.modelValue) {
      state.selectedOption =
        state.suggestions.find((option) => option.id === props.modelValue) ||
        null;
    }
  } catch (error) {
    console.log(error);
  }
};

const mainType = computed(() => {
  let mt = null;

  if (props.type == "PARTNER_SUB_TYPE") {
    mt = store.getters.getSelectedPartnerMainType;
  }

  if (props.type == "SLAB_SUB_STRUCTURE_INTERMEDIATE") {
    mt = store.getters.getSelectedInterSlabStruct;
  }

  if (props.type == "SLAB_SUB_STRUCTURE_CLOSING") {
    mt = store.getters.getSelectedClosingSlabStruct;
  }

  if (props.type == "COOLING_HEATING_TYPE") {
    mt = store.getters.getSelectedCoHeFunction;
  }

  if (props.type == "DOCUMENT_SUB_TYPE") {
    mt = store.getters.getSelectedDocType;
  }

  return mt;
});

const filteredSuggestions = computed(() => {
  if (
    props.type == "PARTNER_SUB_TYPE" ||
    props.type == "SLAB_SUB_STRUCTURE_INTERMEDIATE" ||
    props.type == "SLAB_SUB_STRUCTURE_CLOSING" ||
    props.type == "COOLING_HEATING_TYPE" ||
    props.type == "DOCUMENT_SUB_TYPE"
  ) {
    return state.suggestions.filter(
      (suggestion) => suggestion.parent.id == mainType.value
    );
  }
  return state.suggestions;
});

watch(
  () => props.modelValue,
  (newValue) => {
    state.selectedOption =
      state.suggestions.find((option) => option.id === newValue) || null;
  }
);

watch(
  () => state.selectedOption,
  (newValue) => {
    if (newValue) {
      emits("update:modelValue", newValue.id);
      if (props.type == "PARTNER_MAIN_TYPE") {
        store.commit("setSelectedPartnerMainType", newValue.id);
      }

      if (props.type == "SLAB_STRUCTURE_INTERMEDIATE") {
        store.commit("setSelectedInterSlabStruct", newValue.id);
      }

      if (props.type == "SLAB_STRUCTURE_CLOSING") {
        store.commit("setSelectedClosingSlabStruct", newValue.id);
      }

      if (props.type == "COOLING_HEATING_FUNCTION") {
        store.commit("setSelectedCoHeFunction", newValue.id);
      }

      if (props.type == "DOCUMENT_TYPE") {
        store.commit("setSelectedDocType", newValue.id);
      }
    }else {
      emits("update:modelValue", null)
    }
  }
);

onMounted(() => {
  // getOptionTypes()
  getOptions();
});
</script>

<style>
:root, :host{
  --vs-controls-size: 0.7;
}

.v-select .vs__dropdown-toggle {
  background-color: hsl(220, 17.647%, 20%);
}

.v-select .vs__dropdown-menu {
  @apply border border-gray-700 rounded-md text-sm;
  background-color: hsl(220, 17.647%, 20%);
}

.v-select .vs__selected {
  @apply text-gray-200 text-sm;
}

.v-select .vs__open-indicator {
  @apply text-gray-200 text-sm;
}

.v-select .vs__search::placeholder {
  @apply text-sm;
}



.v-select .vs__clear,
.v-select .vs__open-indicator {
  fill: rgba(255, 255, 255, 0.623);
}
</style>
