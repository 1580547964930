<!------------
README

<ImFormInput 
v-model:inputValue="VARIABLE FOR TWO WAY BINDING WITH THE INPUT VALUE" 
v-model:parent="change this variable to trigger onchange method"
v-model:validated="signals if the field is validated"

type="THE TYPE OF THE INPUT FIELD EG. TEXT, NUMBER, EMAIL
    UNIQUE VERSION ARE 
        LABEL - FORM SECTION LABEL WITH HORIZONTAL RULE
        CHECKBOX - DISPLAYED AS A YES-NO SELECT
        OPTION - FETCHES AZ OPTIONVALUE
            USE THE OPTIONVALUE FIELD TO DEFINE THE OPTIONVALUE TYPE
        OPTIONAPI - FETCHES VALUES FROM A DEDICATED ENDPOINT
            USE THE ENDPOINT FIELD TO DEFINE THE ENDPOINT 
            THE VALUE OF THE OPTIONS WILL BE THE RECORD ID
            USE THE DISPLAYVALUE FIELD TO DEFINE THE OPTIONS LABEL FIELD" 
        OPTIONMANUAL - GIVE THE OPTIONS IN AN ARRAY TROUGH THE manualOptions PROPERTY
        OPTIONSEARCHENDPOINT - USES THE OptionSearchEndpoint COMPONENT

title="LABEL OF THE INPUT FIELD" 
manualOptions="LIST OF THE OPTIONS FOR OPTIONMANUAL TYPE" 
    format: [
        {
            label: LABEL_OF_OPTION,
            value: VALUE_OF_OPTION,
        },
        {
            label: LABEL_OF_OPTION,
            value: VALUE_OF_OPTION,
        }
    ]

mandatory="is the field mandatory"

valueLabel="LABEL OF THE INITIAL VALUE"
searchable="SEARCH BETWEEN THE OPTIONS (OPTION AND OPTIONAPi ONLY)"

searchable="TRUE / FALSE - ENABLES SEARCHING IN THE OPTIONS"

optionValue="OPTIONVALUE TO FETCH THE OPTIONS FROM E.G. DOCUMENT_TYPE (ONLY FOR OPTIONVALUE)"   

endPoint="ENDPOINT TO FETCH THE OPTIONS FROM" 
displayValue="WHICH FIELD SHOULD BE THE LABEL OF THE OPTION (ONLY FOR OPTIONAPI)"

onchangeMethod= (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) {}
reacts to v-model:parent change

validationMethod= (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) {}
reacts to inputValue change
>
</ImFormInput>


-->

<template>
    <div>
        <div v-if="type == 'label'">
            <h2 class=" text-xl text-secondary w-full font-bold">{{ title }}</h2>
            <hr class="mb-8" />
        </div>
        <div v-else>
            <RemLabel v-model="inputValue" :mandatory="mandatory" :title="title" :helperText="helperText"></RemLabel>

            <!-- eslint-disable -->
            <RemFormInput v-model:inputValue="inputValue" v-model:parent="parent" v-model:validated="validated" :config="config"></RemFormInput>
            <!-- eslint-enable -->
            

            
        </div>

    </div>


</template>

<script setup>
import RemFormInput from './REM/imFormInput/RemFormInput.vue';

import RemLabel from './REM/imFormInput/RemLabel.vue';



import { defineModel } from 'vue';

const props = defineProps(['title', 'type', 'valueLabel', 'optionValue', 'endPoint', 'displayValue', 'searchable', 'manualOptions', 'onchangeMethod', 'validationMethod', 'optionSearchEndpoint', 'helperText', 'mandatory']);
const inputValue = defineModel('inputValue');
const parent = defineModel('parent')
const validated = defineModel('validated')

const config = {
    title: props.title,
    type: props.type,
    valueLabel: props.valueLabel,
    optionValue: props.optionValue,
    endpoint: props.endPoint,
    displayValue: props.displayValue,
    searchable: props.searchable,
    manualOptions: props.manualOptions,
    onchangeMethod: props.onchangeMethod,
    validationMethod: props.validationMethod,
    optionSearchEndpoint: props.optionSearchEndpoint,
    mandatory: props.mandatory

}


</script>

