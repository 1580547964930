export default [
    {
      title: "floorArea",
      value: "",
      model: "floorArea",
      type: "number",
    },
    {
      title: "mtArea",
      value: "",
      model: "mtArea",
      type: "number",
    },
    {
      title: "numberOfPremises",
      value: "",
      model: "premiseNumber",
      type: "number",
    },
    {
      title: "mainFunction",
      value: "",
      valueLabel: "",
      model: "mainFunction",
      type: "option",
      optionValue: "BUILDING_MAIN_FUNCTION",
    },
    {
      title: "isAccessible",
      value: "",
      model: "isAccessible",
      type: "checkbox",
    },
    {
      title: "numberDedicated",
      value: "",
      valueLabel: "",
      model: "numberDedicated",
      type: "number",
      helperText: "numberDedicatedHelperText"
    },
    {
      title: "numberCanBeSeated",
      value: "",
      valueLabel: "",
      model: "numberCanBeSeated",
      type: "number",
      helperText: "numberCanBeSeatedHelperText"
    },
    {
      title: "numberSeated",
      value: "",
      valueLabel: "",
      model: "numberSeated",
      type: "number",
      helperText: "numberSeatedHelperText"
    },
    {
      title: "buildingMaxHeight",
      value: "",
      model: "maxHeight",
      type: "number",
    },
    {
      title: "status",
      value: "",
      valueLabel: "",
      model: "status",
      type: "option",
      optionValue: "BUILDING_STATUS",
    },
    {
      title: "floorsBelowSurface",
      value: "",
      model: "floorsBelowSurface",
      type: "text",
    },
    {
      title: "floorsAboveSurface",
      value: "",
      model: "floorsAboveSurface",
      type: "text",
    },
    {
      title: "attic",
      value: "",
      model: "attic",
      type: "checkbox",
    },
    {
      title: "atticCanBeUsed",
      value: "",
      model: "atticCanBeUsed",
      type: "checkbox",
    },
    {
      title: "sapBuildingId",
      value: "",
      valueLabel: "",
      model: "sap",
      type: "option",
      optionValue: "SAP_BUILDING_IDENTIFIER",
    },
    {
      title: "tcafm",
      value: "",
      model: "tcafm",
      type: "text",
    },
    {
      title: "parcelNumber",
      value: "",
      model: "parcelNumber",
      type: "text",
    },
    {
      title: "mtResponsibles",
      type: "label"
    },
    {
      title: "operation",
      value: "",
      model: "operation",
      type: "text",
    },
    {
      title: "cleaning",
      value: "",
      model: "cleaner",
      type: "text",
    },
    {
      title: "supplies",
      value: "",
      model: "supplies",
      type: "text",
    },
    {
      title: "tui",
      value: "",
      model: "tui",
      type: "text",
    },
    {
      title: "ownerOfAssets",
      value: "",
      model: "ownerOfAssets",
      type: "text",
    },
    {
      title: "assetManager",
      value: "",
      model: "assetManager",
      type: "text",
    },
  ]