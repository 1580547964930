export default {
  data: [
    {
      title: "insulationTime",
      value: "",
      valueLabel: "",
      model: "date",
      type: "date",
    },
    {
      title: "insulationMaterial",
      value: "",
      model: "material",
      type: "option",
      optionValue: "INSULATION_MATERIAL",
    },
    {
      title: "note",
      value: "",
      model: "note",
      type: "text",
    },
  ],
};
