<template>
    <div>

        <RemPrimaryButton v-if="saveMethod && store.getters.getRemFormEdit == true && saveValidation == true" :method="saveMethod" :label="$t('message.save')" class="mr-4"></RemPrimaryButton>
        <RemPrimaryButton v-if="User.hasPermission('RemDelete') && deleteMethod && store.getters.getRemFormEdit == true" :method="deleteMethod" :label="$t('message.delete')" class="mr-4"></RemPrimaryButton>
        <RemPrimaryButton v-if="store.getters.getRemFormEdit == false" :method="() => {store.commit('setRemFormEdit', true)}" :label="$t('message.edit')" class="mr-4"></RemPrimaryButton>
        <!--<RemPrimaryButton v-if="store.getters.getRemFormEdit == true" :method="() => {store.commit('setRemFormEdit', false)}" :label="$t('message.back')" class="mr-4"></RemPrimaryButton>-->

    </div>
</template>

<script setup>
import User from '../../misc/User';
import store from "@/misc/vuex-store";
import RemPrimaryButton from './RemPrimaryButton.vue';
import { defineModel } from 'vue';

const saveValidation = defineModel('saveValidation');
if(saveValidation.value == undefined){
    saveValidation.value = true
}

defineProps(['saveMethod', 'deleteMethod']);

</script>

