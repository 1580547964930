export default {
  data: [
     // {
    //   title: "gist",
    //   value: "",
    //   model: "gistId",
    //   optionSearchEndpoint: "gist",
    //   type: "optionSearchEndpoint",
    // },
    {
      title: "meterType",
      value: "",
      valueLabel: "",
      model: "type",
      type: "option",
      optionValue: "METER_TYPE",
    },
    {
      title: "contractingParty",
      value: "",
      optionSearchEndpoint: "partner",
      model: "partnerId",
      type: "optionSearchEndpoint",
    },
    {
      title: "publicUtilityType",
      value: "",
      model: "utilityType",
      type: "option",
      optionValue: "METER_UTILITY_TYPE",
    },
    {
      title: "identifier",
      value: "",
      model: "identifier",
      type: "text",
    },
    {
      title: "placement",
      value: "",
      model: "placement",
      type: "text",
    },
    {
      title: "readings",
      value: "",
      model: "readings",
      type: "text",
    },
    {
      title: "readingFrequency",
      value: "",
      model: "readingFrequency",
      type: "option",
      optionValue: "METER_READING_FREQUENCY",
    },
    {
      title: "commType",
      value: "",
      type: "option",
      model: "commType",
      optionValue: "METER_COMM_TYPE",
    },
    {
      title: "expirationDate",
      value: "",
      model: "expirationDate",
      type: "date",
    },
  ]
}