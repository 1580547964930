<template>
    <div class=" h-fit px-2 overflow-y-auto mx-30 k">
        <header class="w-full ">
            <slot name="remformheader"></slot>


        </header>
        <main class="w-full ">
            <div class="flex md:flex-row md:columns-2 flex-col w-full ">
                <div
                    class="flex flex-row md:flex-col im-sidemenu-contanier rounded-xl mb-4 md:mb-0 flex-wrap md:flex-nowrap w-fit md:md:w-auto justify-center">
                    <slot name="remformsidemenu"></slot>
                </div>
                <TransitionGroup name="fade">
                    <slot name="remformmain"></slot>
                </TransitionGroup>
            </div>

        </main>
        <div style="height: 10rem"></div>
    </div>
</template>


<style scoped>


:deep(.im-main-card):not(.popper, .popper-box) {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    padding: 3rem;
    width: 100%;
    max-width: 100%;
    max-height: fit-content;
    overflow-x: auto;
}

@media screen and (max-width: 600px) {
    .im-main-card {
        margin-bottom: 30px;
    }


}

:deep(.im-main-card-starter):not(.popper, .popper-box) {
    animation: 0.5s ease-out 0s 1 scaleupY;
}

:deep(.im-main-card-starter div):not(.popper, .popper-box) {
    animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
    0% {
        transform-origin: left top;
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

:deep(.im-sidemenu-contanier):not(.popper, .popper-box) {
    animation: 0.5s ease-out 0s 1 scaleupY;
    padding: 1rem;

    margin-right: 2rem;
    height: fit-content;
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

:deep(.im-sidemenu-contanier div):not(.popper, .popper-box) {
    animation: 1.5s ease-out 0s 1 fadein;
}

:deep(.im-card-form):not(.popper, .popper-box) {
    /* inside the card*/ 
    padding-top: 2rem;
}

:deep(.fade-enter-active):not(.popper, .popper-box) {
    transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

:deep(.fade-leave-active):not(.popper, .popper-box) {
    transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

:deep(.fade-leave-to):not(.popper, .popper-box) {
    padding: 0;
    width: 0;
    opacity: 0;
}

:deep(.fade-enter-from):not(.popper, .popper-box) {
    padding: 0;
    width: 0;
    opacity: 0;
}

:deep(.im-round-btn):not(.popper, .popper-box) {
    border-radius: 9999px;
}


@keyframes fadeinbtn {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}



.im-sidemenu-contanier:hover .im-btn-text {
    animation: .5s ease-out 0s 1 fadeinbtn;
    display: inline;
}



</style>
