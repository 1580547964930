<template>
    <div>
        <div v-if="searchable == true" class="dropdown">
            <input type="hidden" v-model="inputValue" class="input input-sm input-bordered w-full"
                :placeholder="$t('message.select')">
            <div class="im-dropdown w-full ">
                <input type="text" list="optionList" v-model="optionSearchValue" @focusin="focus = true"
                    @focusout="setFocus" class="input input-sm input-bordered w-full" :mandatory="mandatory"
                    :placeholder="$t('message.select')">

                <ul class="im-dropdown-content bg-base-100 rounded-box z-50 w-full shadow z-40"
                    v-if="focus == true">
                    <li class="option-hover"><a @click="optionSearchValue = ''; inputValue = ''">{{ $t('message.select') }}</a></li>
                    <li class="option-hover" v-for="op in options" v-bind:key="op.value + op.label"
                        @click="optionSearchValue = op.label; inputValue = op.value">{{ op.label }}</li>
                </ul>
            </div>

        </div>
        <div v-else class="dropdown">
            <select v-model="inputValue" class="input input-sm input-bordered w-full" :mandatory="mandatory"  @focusout="focus = false">
                <option value="">{{ $t('message.select') }}</option>
                <option value="null">{{ $t('message.empty') }}</option>

                <option v-for="op in options" v-bind:key="op.value + op.label" :value="op.value" :parent="op.parent">{{
                    op.label }}
                </option>
            </select>
        </div>
    </div>
</template>

<script setup>
import { defineModel, onMounted, ref, computed, watch } from 'vue';
import Axios from "@/misc/axios";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import store from "@/misc/vuex-store";

const props = defineProps(['type', 'valueLabel', 'optionValue', 'endPoint', 'displayValue', 'searchable', 'manualOptions', 'onchangeMethod', 'validationMethod', 'mandatory']);

const inputValue = defineModel('inputValue')
const parent = defineModel('parent')
const validated = defineModel('validated')
const inputError = defineModel('inputError')

const options = ref([])

let optionsRepo = [];

const optionSearchValue = ref(props.valueLabel);

const focus = defineModel('focus');
focus.value = false

const language = computed(() => store.getters.getLanguage);

onMounted(() => {

    if (props.type == "option" && props.optionValue) {

        Axios.get(
            TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
            "/rem/option/options/" +
            props.optionValue,
            {
                'Accept-Language': language.value
            },
            {
                onTokenRefreshed: () => {
                    this.asyncGet();
                },
            }
        ).then(function (resp) {
            //console.log(e.optionValue);
            //console.log(resp.data);

            resp.data.forEach((op) => {
                options.value.push({
                    label: op.value,
                    value: op.id,
                    parent: op.parent != null ? op.parent.id : "",

                });
            });
        });
    } else if (props.type == "optionapi" && props.endPoint) {
        Axios.get(
            props.endPoint,
            {
                page: 0,
                size: 1,
                sort: ["id"],
            },
            {
                onTokenRefreshed: () => {
                    this.asyncGet();
                },
            }
        ).then(function (resp) {
            if (resp.data && resp.data.content) {
                resp.data.content.forEach((op) => {
                    /*options.value.push({
                        label: op[props.displayValue],
                        value: op.id,
                    });*/
                    optionsRepo.push({
                        label: op[props.displayValue],
                        value: op.id,
                    });

                });
            }
        });

    } else if (props.type == "optionapi" && !props.endPoint && inputValue.value != "") {
        options.value.push({
            label: inputValue.value,
            value: inputValue.value
        });
        optionsRepo.push({
            label: inputValue.value,
            value: inputValue.value
        });


    } else if (props.type == "optionManual" && props.manualOptions) {
        options.value = props.manualOptions;
    }
    optionsRepo = options.value
})

const setFocus = function () {
    setTimeout(() => { focus.value = false }, 500);
}

watch(optionSearchValue, (newValue, oldValue) => {
    if (newValue == "") {
        options.value = optionsRepo
    }
    else if (oldValue != newValue) {
        options.value = optionsRepo.filter(elem => (elem.label.toLowerCase()).includes(newValue.toLowerCase()));
    }
    // console.log(newValue + " " + oldValue);

})

watch(() => parent.value, (newValue) => {
    if (props.onchangeMethod && (newValue != null && newValue != "")) {
        props.onchangeMethod(options, optionsRepo, newValue, inputValue, inputError)
    }

    if (props.mandatory && inputValue.value == "" && parent == "") {
        validated.value = false
    } else {
        validated.value = true;
    }
})

watch(() => inputValue.value, (newValue, oldValue) => {

    if (props.mandatory && inputValue.value == "") {
        validated.value = false
    } else {
        validated.value = true;
    }

    if (props.validationMethod && newValue != '' && newValue != oldValue) {
        props.validationMethod(options, optionsRepo, "", newValue, oldValue, inputValue, inputError)
        if (inputError.value == "") {
            validated.value = true
        } else {
            validated.value = false
        }
    }


})
</script>


<style scoped>
.im-dropdown-content {
    position: relative
}

.im-invisible {
    display: none;

}

.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dropdown::before {
    position: absolute;
    font-size: 12px;
    content: " \25BC";
    top: 7px;
    right: -8px;
    height: 20px;
    width: 28px;
    pointer-events: none;
}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.option-hover{
    padding: 5px 0 5px 5px;
    font-size: 0.875rem;
}

.option-hover:hover{
    background-color: rgb(147, 185, 247);
    color: #242933;
}

ul .option-hover:first-of-type
{
    border-radius: 10px 10px 0 0;
} 

ul .option-hover:last-of-type
{
    border-radius: 0 0 10px 10px;
} 
</style>