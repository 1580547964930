const messages = {
  hu: {
    message: {
      "": "",
      accountNumber: "Számlaszám",
      accounting: "Elszámolás",
      actions: "Műveletek",
      adapterConfig: "Adapter konfiguráció",
      adapterType: "Adapter típusa",
      addDevice: "Eszköz hozzáadása",
      addLegalSituation: "Új jogi helyzet",
      addNew: "Új hozzáadása",
      addNewAddress: "Új cím",
      addNewAggregation: "Új aggregáció hozzáadása",
      addNewBuilding: "Új épület hozzáadása",
      addNewContact: "Új kapcsolattartó felvétele",
      addNewMeter: "Új mérőóra",
      addNewPartner: "Partner hozzáadása",
      addNewPartnerType: "Partnertípus hozzáadása",
      addNewProperty: "Új ingatlan hozzáadása",
      addNewSiteDesign: "Új hozzáadása",
      addNewUtilization: "Új hasznosítás",
      addPremise: "Új helyiség hozzáadása",
      additionalInfo: "Kiegészítő adatok",
      address: "Cím",
      addressType: "Cím típusa",
      addresses: "Címek",
      admin: "Admin",
      aggregationFunction: "Aggregációs függvény",
      aggregationNamePlaceholder: "pl. Aggregáció neve",
      aggregations: "Aggregációk",
      airConditionerOutdoorUnit: "Klíma kültéri egység",
      allRelatedPartner: "Minden kapcsolódó partner",
      annualPerformance: "Éves teljesítmény (kW)",
      anonymus: "Névtelen",
      areaSize: "Alapterület (m2)",
      areas: "Areák",
      assetManager: "Vagyongazda",
      atleastOneCharacter: "Min. 1 karakter...",
      attic: "Tetőtér van?",
      atticCanBeUsed: "Hasznosítható?",
      back: "Vissza",
      backToTheMap: "Vissza a térképhez",
      baseDatas: "Adatok",
      batteryCharge: "Akkumulátor töltöttsége",
      batteryInstallDate: "Akkumulátor telepítésének ideje",
      bearingMasonry: "Teherhordó falazat",
      breakdown: "Bontás",
      building: "Épület",
      buildingDeleteConfirm: "Biztos, hogy törölni szeretné ezt az épületet?",
      buildingMaxHeight: "Épület legnagyobb magassága",
      buildingPagePremiseNumber: "Helyiségjelölő szám",
      buildingPartnerModeSwitch:
        "Váltás a partner módok között (Kizárólag az épület vagy az épület és a hozzátartozó helyiségek partnereinek megjelenítése)",
      buildingPartners: "Épülethez kapcsolódó partnerek",
      buildings: "Épületek",
      builtInFireProtection: "Kiépített tűzvédelem",
      canBeControlled: "Vezérelhető?",
      canBeCooled: "Hűthető?",
      canBeHeated: "Fűthető?",
      canBePhased: "Szakaszolható",
      cancel: "Mégse",
      centralAirConditioner: "Központi klíma",
      centralHeating: "Központi fűtés",
      choose: "Kiválasztás",
      chooseLang: "Válasszon nyelvet: ",
      city: "Település",
      classification: "Besorolás",
      cleaning: "Takarítás",
      close: "BEZÁRÁS",
      codeFHelperText: "F-es kód",
      commType: "Kommunikáció típusa",
      company: "Cég",
      confirm: "Igen",
      confirmRemove: "Biztos benne, hogy törli az eszközt?",
      confirmRemoveLS: "Biztos törölni szeretné ezt a jogi helyzetet?",
      confirmRemovePartner: "Biztos benne, hogy törli a partnert?",
      confirmRemovePremise:
        "Biztos benne, hogy törölni akarja ezt a helyiséget?",
      confirmRemoveScenario: "Biztos benne, hogy törli a scenariot?",
      contactPhone: "Telefonszám",
      contacts: "Kapcsolattartók",
      contractingParty: "Szerződő fél",
      controlled: "Vezérelt",
      cooling: "Hűtő",
      coolingHeatingDevice: "Hűtő-fűtő eszköz",
      coolingHeatingDevices: "Hűtő-fűtő eszközök",
      coolingPerformance: "Hűtőteljesítmény (kW)",
      copiedToClipboard: "Másolva a vágólapra",
      copy: "Másolás",
      country: "Ország",
      county: "Vármegye",
      cremClassification: "CREM besorolás",
      currentPresenceGist: "Aktuális jelenlét",
      data: "Adatok",
      dataAndMtResp: "Alapadatok és MT felelősök",
      date: "Dátum",
      dateOfOrigin: "Kelt",
      day: "nap",
      dedicatedOrgUnit: "Dedikált szervezeti egység",
      delete: "Törlés",
      deleteAddressConfirm: "Biztos, hogy törölni szeretné ezt a címet?",
      deleteConfirm: "Biztos, hogy törölni szeretné a rekordot?",
      deleteDocumentConfirm:
        "Biztos, hogy törölni szeretné ezt a dokumentumot?",
      deleteLegalSituation: "Jogi helyzet törlése",
      deleteLegalSituationConfirm:
        "Biztos, hogy törölni szeretné ezt a jogi helyzetet?",
      deleteMeterConfirm: "Biztos, hogy törölni szeretné ezt a mérőórát?",
      deleteNotSuccessful: "Sikertelen törlés...",
      deleteObligationConfirm:
        "Biztos, hogy törölni szeretné ezt a kötelezettséget?",
      deletePartner: "Partner törlése",
      deleteSuccessful: "Sikeres törlés...",
      deleteUtilizationConfirm:
        "Biztos, hogy törölni szeretné ezt a hasznosítást?",
      deny: "Mégse",
      designation: "Megnevezés",
      device: "Eszköz",
      deviceMap: "Eszköztérkép",
      deviceNamePlaceholder: "pl. Eszköz neve",
      deviceType: "Eszköz típusa",
      dieselGenerator: "Telepített dízel ellátás van?",
      dieselGeneratorTitle: "Telepített dízel ellátás",
      displayName: "Megjelenítendő név",
      district: "Kerület",
      documents: "Dokumentumok",
      door: "Ajtó",
      doorType: "Ajtók típusa",
      download: "Letöltés",
      downloadEkrReport: "EKR riport letöltése",
      edit: "Szerkesztés",
      editAggregation: "Aggregáció szerkesztése",
      editDevice: "Eszköz szerkesztése",
      editDocument: "Dokumentum szerkesztése",
      editMeter: "Mérőóra szerkesztése",
      editPartner: "Partner szerkesztése",
      editPremise: "Helyiség szerkesztése",
      editScenario: "Szkenárió szerkesztése",
      editSiteDesign: "Szerkesztés",
      editUtilization: "Hasznosítás szerkesztése",
      editZone: "Zóna szerkesztése",
      egNumPlaceholder: "pl. 100",
      elevator: "Lift",
      elevatorType: "Lift típusa",
      email: "E-mail",
      emailValidationFail: "Hibás e-mail formátum",
      empty: "Üres",
      endDate: "Záró dátum",
      energetics: "Energetika",
      energyIntake: "Energiafelvétel",
      powerIntake: "Áramfelvétel",
      energyTradingPartner: "Energiakereskedő Partner?",
      english: "Angol",
      entryNoteWarning: "Maximum 2000 karakter adható meg",
      error: "Hiba történt",
      errorAlt: "Az oldal frissítése talán megoldja a problémát",
      expiration: "Lejárati Idő",
      expirationDate: "Lejárati idő",
      externalIdentifier: "Külső azonosító",
      false: "Nem",
      fenestration: "Nyílászáró",
      fileName: "Fájlnév",
      fileType: "Fájltípus",
      fireProtection: "Tűzvédelem",
      fireProtectionClass: "Tűzvédelmi besorolás",
      flatRoof: "Lapostető?",
      floc: "FLOC Azonosító",
      floor: "Emelet",
      floorArea: "Alapterület (m2)",
      floorCovering: "Burkolat",
      floorSpace: "Alapterület (m2)",
      floors: "Szintek",
      floorsAboveSurface: "Emeletek száma a felszín felett",
      floorsBelowSurface: "Emeletek száma a felszín alatt",
      foundation: "Alapozás",
      fuelCellGenerator: "Üzemanyag cellás generátor van?",
      fuelCellGeneratorTitle: "Üzemanyag cellás generátor",
      function: "Funkció",
      generateApiKeyButtonTitle: "Új API kulcs generálása",
      generateApiKeyExample: "Példa",
      generateApiKeyTitle: "API kulcs",
      gist: "Gist",
      globalSiteDesignNotFound:
        "Site design nem található, frissítse ezt az adaptert.",
      goBack: "Visszalépés",
      gpsCoordinates: "GPS koordináták",
      guarantee: "Garancia",
      heating: "Fűtő",
      heatingPerformance: "Fűtőteljesítmény (kW)",
      hour: "óra",
      houseNumber: "Házszám",
      hungarian: "Magyar",
      identifier: "Azonosító",
      infos: "Infók",
      installTime: "Telepítés ideje",
      installationDate: "Üzembe helyezés ideje",
      insulation: "Hőszigetelés",
      invalidMapView: "A betöltött térkép adatai nem megfelelőek.",
      inverterManufacturer: "Inverter gyártója",
      inverterPerformance: "Inverter teljesítménye (kW)",
      inverterType: "Inverter típusa",
      isAccessible: "Akadálymentesített?",
      isActive: "Aktív?",
      isCentral: "Központi",
      isDedicated: "Dedikált szervezeti egység?",
      isEmergencyElevator: "Mentő lift?",
      isEscapeRoute: "Menekülési útvonal van?",
      isFalseCeiling: "Álmennyezet?",
      isFalseFloor: "Álpadló?",
      isSmokeFree: "Füstmentes?",
      kng: "KING",
      latitude: "Szélesség",
      legalRelationship: "Alapviszony jellege",
      legalRelationshipEnd: "Jogviszony vége",
      legalRelationshipStart: "Jogviszony kezdete",
      legalRelationshipUtilization: "Hasznosítás jogviszonya",
      legalSituation: "Jogi helyzet",
      legalSituationEnd: "Jogviszony vége",
      legalSituationStart: "Jogviszony kezdete",
      legalSituations: "Jogi helyzetek",
      levels: "Szintek",
      level: "Szint",
      lidarView: "LIDAR Nézet",
      liquidCoolerCoolingPerformance: "Folyadékhűtő hűtési teljesítmény",
      liquidCoolerMaxElectricPerformance:
        "Folyadékhűtő elektromos teljesítménye maximum",
      listOfDevices: "Eszközök listája",
      loadCapacity: "Teherbírás",
      loadedAreaHasNoCoordinates:
        "A betöltött területnek nincsenek meghatározott földrajzi koordinátái.",
      loading: "Betöltés...",
      loginAgain: "Belépek újra",
      logoutInProgress: "Kijelentkezés folyamatban...",
      longitude: "Hosszúság",
      mainFunction: "Fő funkció",
      mainType: "Főtípus",
      maintainedBy: "Karbantartást végzi (szervezeti egység)",
      maintenanceCycle: "Karbantartási ciklus (hónap)",
      manufacturer: "Gyártó",
      mapImage: "Térkép kép",
      mapView: "Térképnézet",
      insulationMaterial: "Szigetelés anyaga",
      maxEnergyIntake: "Maximális villamosenergia felvétele (kW)",
      maxHeight: "Legnagyobb belmagasság",
      maxValue: "Max. érték",
      messages: "Szövegezés",
      meterType: "Mérőóra fajta",
      meters: "Mérőórák",
      minHeight: "Legkisebb belmagasság",
      minValue: "Min. érték",
      minutes: "perc",
      missingApiKey: "API kulcs még nincs generálva",
      missingFormFields: "Kérjük töltse ki a hiányos mezőket",
      missingMandatoryField: "Hiányos kötelező mezők",
      mtArea: "MT releváns négyzetméter",
      mtResponsibles: "MT-n belüli felelősök",
      name: "Név",
      new: "Új",
      newOptionValue: "Új Érték",
      newScenario: "Új szkenárió",
      nextMaintenance: "Következő karbantartás ideje",
      no: "Nem",
      noAppliedFilters: "Nincs beállított szűrőfeltétel...",
      noAssignedDevice: "Nincs hozzárendelt eszköz",
      noBuildingsFound: "Ehhez az ingatlanhoz nem találhatók épületek",
      noDevice: "Jelenleg nincs eszköz hozzáadva",
      noFloorsFound: "Ehhez az épülethez nem találhatók szintek.",
      noMatchingOptions:
        "Nem található a keresési kifejezéssel megegyező érték.",
      noResults: "Nincs találat...",
      noSDChildrenFound: "Ehhez a site designhoz nem található al site design",
      noScenarioAddedCurrently: "Jelenleg nincs scenario hozzáadva.",
      noSubArea: "Ehhez a területhez nem tartozik alterület.",
      noViewImage: "A nézethez nincs elérhető képi információ!",
      notSuccessfulSave: "Sikertelen mentés...",
      note: "Megjegyzés",
      notes: "Bejutási megjegyzések",
      number: "Szám",
      numberCanBeSeated: "Ültethető létszám",
      numberCanBeSeatedHelperText:
        "Számított adat, a helyiség alapterületéből 3nm/fő értékkel számítva.",
      numberDedicated: "Dedikált létszám",
      numberDedicatedHelperText:
        "A HR által, a telefonkönyvben szerepeltetve az adott ingatlanhoz rendelt kollégák létszáma.",
      numberOfElevators: "Felvonók száma",
      numberOfPerson: "Személyek száma",
      numberOfPossibleWorkplaces: "Lehetséges m.áll. száma",
      numberOfPremises: "Helyiségek száma (db szám)",
      numberOfStairways: "Lépcsőházak száma",
      numberOfWorkplaces: "M. áll száma",
      numberSeated: "Ültetett létszám",
      numberSeatedHelperText:
        "Az adott helyiségben kialakított munkaállomások száma.",
      obligation: "Kötelezettség",
      obligations: "Kötelezettségek",
      obliged: "Kötelezett",
      offGrid: "Szigetüzem",
      open: "Megnyitás",
      openableWindow: "Nyitható ablak van?",
      operation: "Üzemeltetés",
      optionAdminEditorLink: "Adminisztráció",
      optionEditorLink: "Szerkesztés",
      optionEditorPage: "Értékkészlet kezelés",
      optionValue: "Értékkészlet",
      optionValueDuplicateTr: "Ez a fordítás már létezik",
      optionValueTokenEmpty: "Nincs megadott érték",
      optionsNumber: "Opciók száma",
      order: "Sorszám",
      otherRight: "Egyéb jog megnevezése",
      outdoorUnit: "Klíma kültéri egység van?",
      outgoingIPAddress: "Kimenő IP cím",
      ownerOfAssets: "Eszközgazda",
      ownership: "Tulajdoni hányad",
      parcelNumber: "Helyrajzi szám",
      parent: "Szülő",
      partner: "Partner",
      partners: "Partnerek",
      paymentObligations: "Fizetési kötelezettség",
      performance: "Teljesítmény",
      performanceTransfer: "Teljesítmény átadás",
      permission: "Jogosultság",
      permissionDenied: "Hozzáférés megtagadva",
      personnel: "Létszám adat",
      placement: "Elhelyezés",
      populationData: "LÉTSZÁM ADATOK",
      position: "Pozíció",
      postal: "Irányítószám",
      postalError: "Hibás irányítószám",
      postalRegex:
        "A megyék és települések megjelenítéséhez töltse ki az irányítószám mezőt!",
      powerProduced: "Termelt teljesítmény (kW)",
      premise: "Helyiség",
      premiseNumber: "Helyiségjelölő szám",
      premises: "Helyiségek",
      preview: "Előnézet",
      primary: "Elsődleges",
      privateIndividual: "Magánszemély",
      profile: "Profil",
      propAddressError: "Mentés előtt a cím szekció kitöltése kötelező!",
      propellant: "Meghajtóanyag",
      properties: "Ingatlanok",
      property: "Ingatlan",
      propertyManagementModule: "Ingatlankezelő modul",
      propertyPartnerModeSwitch:
        "Váltás a partner módok között (Kizárólag az ingatlan vagy az ingatlan és a hozzátartozó épületek valamint helyiségek partnereinek megjelenítése)",
      propertyPartners: "Ingatlanhoz kapcsolódó partnerek",
      propertySheetAppellation: "Tulajdoni lap szerinti megnevezés",
      protection: "Védettség",
      protectionNote: "Védettség leírás",
      publicAreaName: "Közterület neve",
      publicAreaType: "Közterület jellege",
      publicUtilityType: "Közmű típus",
      rain: "Csapadék: ",
      rank: "Beosztás",
      readingFrequency: "Leolvasások gyakorisága",
      readings: "Leolvasások",
      regenerateApiKeyConfirmation: "Biztosan újra generálja az API kulcsot?",
      regenerateApiKeyConfirmationCancel: "Mégse",
      regenerateApiKeyConfirmationOk: "Igen",
      reports: "Riportok",
      resolution: "Bontás",
      responders: "Szkenáriók",
      roofType: "Tető típusa",
      roofing: "Tetőfedés",
      "rvg-automatic-list-refresh-1-min":
        "Automatikus lista frissítés percenként",
      "rvg-automatic-list-refresh-30-sec":
        "Automatikus lista frissítés 30 mp-ként",
      "rvg-automatic-list-refresh-5-min":
        "Automatikus lista frissítés 5 percenként",
      rvg_automatic_list_refresh_1_min:
        "Automatikus lista frissítés percenként",
      rvg_automatic_list_refresh_30_sec:
        "Automatikus lista frissítés 30 mp-ként",
      rvg_automatic_list_refresh_5_min:
        "Automatikus lista frissítés 5 percenként",
      sap: "SAP kód",
      sapBuildingId: "SAP épület azonosító",
      save: "Mentés",
      saveConfirmation: "Menti a módosításait?",
      saveError: "A mentés közben hibák léptek fel.",
      scenarios: "Szkenáriók",
      search: "Keresés",
      searchGeneric: "Általános Kereső",
      searchSiteDesign: "Terület Kereső",
      searchZone: "Zóna Kereső",
      searchingDevice: "Eszköz keresése...",
      secondary: "Másodlagos",
      select: "Kiválasztás",
      selectInputs: "Bemenetek kiválasztása",
      selectOutput: "Kimenet kiválasztása",
      selectTime: "Időpont beállítása",
      sendToOrder: "Küldd ide",
      sessionExpired: "A munkamenet lejárt, jelentkezzen be újra!",
      setPerPremise: "Helyiségenkénti beállítás",
      settlement: "Település",
      sharedUse: "Közös használatú?",
      siteDesign: "Site design",
      siteDesignPlaceholder: "Hozzárendelt NRGmap Site Design",
      slabStructClosing: "Záró födémszerkezet",
      slabStructInter: "Közbenső födémszerkezet",
      slabSubStructClosing: "Záró födém altípus",
      slabSubStructInter: "Közbenső födém altípus",
      solarPanel: "Napelem",
      specialData: "Speciális adatok",
      sqm: "Terület (m2)",
      stairway: "Lépcsőház",
      stairwayId: "Lépcsőház azonosító",
      stairwayType: "Lépcsőház típusa",
      startDate: "Kezdő dátum",
      status: "Státusz",
      structureTypes: "Szerkezet típusok",
      subType: "Altípus",
      successfulSave: "Sikeres mentés...",
      supplies: "Teakonyha ellátmány",
      syncSites: "Frissítése",
      system: "Rendszer",
      szepaClass: "SZEPA besorolás",
      tankSize: "Tartály mérete",
      taxId: "Adóazonosító jel",
      taxNumber: "Adószám",
      tcafm: "TCAFM kód",
      technicalData: "Műszaki adatok",
      temperatureData: "HŐMÉRSÉKLETI ADATOK",
      temperatureMap: "Hőmérsékleti térkép",
      test: "Teszt",
      translations: "Fordítások",
      true: "Igen",
      tui: "TU",
      type: "Típus",
      typeGistName: "Kérem kezdje el beírni a gist nevét!",
      unauthorizedTenant:
        "Az Ön szervezete számára nem engedélyezett az alkalmazás használata!",
      updateAggregationGists: "Aggregációs eszköz lista frissítése",
      updateContact: "Kapcsolattartó adatainak szerkesztése",
      updateLegalSituation: "Jogi helyzet szerkesztése",
      updateProperty: "Ingatlan szerkesztése",
      upload: "Feltöltés",
      uploadDate: "Feltöltés dátuma",
      uploadFile: "Fájl feltöltése",
      uploader: "Feltöltő",
      ups: "Szünetmentes táp",
      useOfGeneratedHeat: "Felhasználása a keletkezett hőnek",
      utilization: "Hasznosítás",
      utilizationDeleteConfirm:
        "Biztos, hogy törölni szeretné ezt a hasznosítást?",
      utilizations: "Hasznosítások",
      value: "Érték",
      windowDepth: "Ablak mélység",
      windowHeight: "Ablak magasság",
      windowMaterial: "Ablakok anyaga",
      windowNumber: "Ablakok száma",
      windowSize: "Ablakok mérete",
      windowWidth: "Ablak szélesség",
      windows: "Ablakok",
      yearOfManufacture: "Gyártási év",
      yes: "Igen",
      zoneAdmin: "Zóna admin",
      zoneGroups: "Zónacsoportok",
      zoneMap: "Zónatérkép",
      zoneName: "A zóna neve",
      zoneNamePlaceholder: "pl. Folyosó",
      zoneType: "Zóna típusa",
      noSiteDesign: "Az épülethez nem tartozik NRGmap site design.",
      insulationTime: "Szigetelés ideje",
      numberOfSolarPanels: "Napelem táblák darabszáma",
      performanceOfSolarPanels: "Napelem tábla teljesítménye"
    },
  },
  en: {
    message: {
      "": "",
      accountNumber: "Account number",
      accounting: "Accounting",
      actions: "Actions",
      adapterConfig: "Adapter Configuration",
      adapterType: "Adapter Type",
      addDevice: "Add Device",
      addLegalSituation: "Add legal situation",
      addNew: "Add new",
      addNewAddress: "New Address",
      addNewAggregation: "Add new Aggregation",
      addNewBuilding: "New building",
      addNewContact: "Add new contact",
      addNewMeter: "New meter",
      addNewPartner: "Add New Partner",
      addNewPartnerType: "Add New Partner Type",
      addNewProperty: "Add new property",
      addNewSiteDesign: "Add New",
      addNewUtilization: "New utilization",
      addPremise: "New premise",
      additionalInfo: "Additional Informations",
      address: "Address",
      addressType: "Address type",
      addresses: "Addresses",
      admin: "Admin",
      aggregationFunction: "Aggregation function",
      aggregationNamePlaceholder: "eg. Aggregation Name",
      aggregations: "Aggregations",
      airConditionerOutdoorUnit: "Air conditioner outdoor Unit",
      allRelatedPartner: "All related partner",
      annualPerformance: "Annual Performance (kW)",
      anonymus: "Anonymus",
      areaSize: "Area",
      assetManager: "Asset manager",
      atleastOneCharacter: "Atleast one character...",
      attic: "Is there an attic?",
      atticCanBeUsed: "Usable?",
      back: "Back",
      backToTheMap: "Back to the map",
      baseDatas: "Datas",
      batteryCharge: "Battery charge",
      batteryInstallDate: "Battery Install Date",
      bearingMasonry: "Bearing masonry",
      breakdown: "Breakdown",
      building: "Building",
      buildingDeleteConfirm: "Are you sure you want to delete this building?",
      buildingMaxHeight: "Max height of the building",
      buildingPagePremiseNumber: "Premise identification number",
      buildingPartnerModeSwitch:
        "Switching between partner modes (Only building related partners or the partners related to any entity what's connected to this building)",
      buildingPartners: "Partners connected to this building",
      buildings: "Buildings",
      builtInFireProtection: "Built in fire protection",
      canBeControlled: "Can be controlled?",
      canBeCooled: "Can be cooled?",
      canBeHeated: "Can be heated?",
      canBePhased: "Can Be Phased",
      cancel: "Cancel",
      centralAirConditioner: "Central air conditioner",
      centralHeating: "Central heating",
      choose: "Choose",
      chooseLang: "Choose Language: ",
      city: "City",
      classification: "Classification",
      cleaning: "Cleaning",
      close: "Close",
      codeFHelperText: "Code beginning with F",
      commType: "Communication type",
      company: "Company",
      confirm: "Confirm",
      confirmRemove: "Are you sure that you want to remove this device?",
      confirmRemoveLS: "Are you sure you want to delete this legal situation?",
      confirmRemovePartner: "Are you sure you want to delete this partner?",
      confirmRemovePremise: "Are you sure you want to delete this premise?",
      confirmRemoveScenario:
        "Are you sure that you want to remove this scenario?",
      contactPhone: "Phone",
      contacts: "Contacts",
      contractingParty: "Contracting party",
      controlled: "Controlled",
      cooling: "Cooling",
      coolingHeatingDevice: "Cooling-Heating Device",
      coolingHeatingDevices: "Cooling-heating devices",
      coolingPerformance: "Cooling performance",
      copiedToClipboard: "Copied to clipboard",
      copy: "Copy",
      country: "Country",
      county: "County",
      cremClassification: "CREM classification",
      currentPresenceGist: "Current presence",
      data: "Data",
      dataAndMtResp: "Base data and MT Responsibles",
      date: "Date",
      dateOfOrigin: "Date of origin",
      day: "day",
      dedicatedOrgUnit: "Dedicated Organization Unit",
      delete: "Delete",
      deleteAddressConfirm: "Are you sure you want to delete this address?",
      deleteConfirm: "Are you sure you want to delete the record?",
      deleteDocumentConfirm: "Are you sure you want to delete this document?",
      deleteLegalSituation: "Delete legal situation",
      deleteLegalSituationConfirm:
        "Are you sure you want to delete this legal situation?",
      deleteMeterConfirm: "Are you sure you want to delete this meter-?",
      deleteNotSuccessful: "Delete was not successful",
      deleteObligationConfirm:
        "Are you sure you want to delete the obligation?",
      deletePartner: "Delete partner",
      deleteSuccessful: "Delete successful",
      deleteUtilizationConfirm:
        "Are you sure you want to delete this utilization?",
      deny: "Deny",
      designation: "Designation",
      device: "Device",
      deviceMap: "Device Map",
      deviceNamePlaceholder: "eg. Device Name",
      deviceType: "Device Type",
      dieselGenerator: "Diesel Generator?",
      dieselGeneratorTitle: "Diesel Generator",
      displayName: "Display Name",
      district: "District",
      documents: "Documents",
      door: "Door",
      doorType: "Door type",
      download: "Download",
      downloadEkrReport: "Download EKR Report",
      edit: "Edit",
      editAggregation: "Edit Aggregation",
      editDevice: "Edit Device",
      editDocument: "Edit document",
      editMeter: "Edit meter",
      editPartner: "Edit Partner",
      editPremise: "Edit premise",
      editScenario: "Edit Scenario",
      editSiteDesign: "Edit",
      editUtilization: "Edit utilization",
      editZone: "Edit Zone",
      egNumPlaceholder: "eg. 100",
      elevator: "Elevator",
      elevatorType: "Elevator type",
      email: "E-mail",
      emailValidationFail: "Invalid e-mail format",
      empty: "Empty",
      endDate: "End date",
      energetics: "Energetics",
      energyIntake: "Energy Intake",
      powerIntake: "Power Intake",
      energyTradingPartner: "Energy Trading Partner?",
      english: "English",
      entryNoteWarning: "The maximum number of characters is 2000",
      error: "Error",
      errorAlt: "A page refresh may solve the problem",
      expiration: "Expiration",
      expirationDate: "Expiration date",
      externalIdentifier: "External Identifier",
      false: "No",
      fenestration: "Doors and windows",
      fileName: "Filename",
      fileType: "File type",
      fireProtection: "Fire Protection",
      fireProtectionClass: "Fire protection class",
      flatRoof: "Flatroof?",
      floc: "FLOC ID",
      floor: "Floor",
      floorArea: "Floor area",
      floorCovering: "Floor covering",
      floorSpace: "Floorspace",
      floors: "Floors",
      floorsAboveSurface: "Floors above surface",
      floorsBelowSurface: "Floors below surface",
      foundation: "Foundation",
      fuelCellGenerator: "Fuel Cell Generator?",
      fuelCellGeneratorTitle: "Fuel Cell Generator",
      function: "Function",
      generateApiKeyButtonTitle: "New API key",
      generateApiKeyExample: "Example",
      generateApiKeyTitle: "API key",
      gist: "Gist",
      globalSiteDesignNotFound:
        "Site design not found, please update this adapter.",
      goBack: "Go to the previous page",
      gpsCoordinates: "GPS Coordinates",
      guarantee: "Guarantee",
      heating: "Heating",
      heatingPerformance: "Heating performance",
      hour: "hour",
      houseNumber: "House number",
      hungarian: "Hungarian",
      identifier: "Identifier",
      infos: "Infos",
      installTime: "Install Time",
      installationDate: "Installation date",
      insulation: "Insulation",
      invalidMapView: "The data of the loaded map is incorrect",
      inverterManufacturer: "Inverter Manufacturer",
      inverterPerformance: "Inverter Performance (kW)",
      inverterType: "Inverter Type",
      isAccessible: "Accessible?",
      isActive: "Active?",
      isCentral: "Central?",
      isDedicated: "Dedicated org unit?",
      isEmergencyElevator: "Emergency elevator?",
      isEscapeRoute: "Escape route?",
      isFalseCeiling: "False ceiling?",
      isFalseFloor: "False floor?",
      isSmokeFree: "Smoke free?",
      kng: "KING",
      latitude: "Latitude",
      legalRelationship: "Legal relationship type",
      legalRelationshipEnd: "Legal relationship end",
      legalRelationshipStart: "Legal relationship start",
      legalRelationshipUtilization: "Lagal Relationship",
      legalSituation: "Legal situation type",
      legalSituationEnd: "Legal situation end date",
      legalSituationStart: "Legal situation start date",
      legalSituations: "Legal situations",
      levels: "Levels",
      lidarView: "LIDAR View",
      liquidCoolerCoolingPerformance: "Liquid Cooler Cooling Performance",
      liquidCoolerMaxElectricPerformance:
        "Liquid Cooler Max Electric Performance",
      listOfDevices: "List of Devices",
      loadCapacity: "Load capacity",
      loadedAreaHasNoCoordinates:
        "The loaded area does not have geographical coordinates.",
      loading: "Loading...",
      loginAgain: "I will log in again",
      logoutInProgress: "Logout in progress...",
      longitude: "Longitude",
      mainFunction: "Main function",
      mainType: "Main type",
      maintainedBy: "Maintained By",
      maintenanceCycle: "Maintenance cycle (month)",
      manufacturer: "Manufacturer",
      mapImage: "Map Image",
      mapView: "Map view",
      insulationMaterial: "Insulation material",
      maxEnergyIntake: "Max Energy Intake (kW)",
      maxHeight: "Max ceiling height",
      maxValue: "Max. value",
      messages: "Text tokens",
      meterType: "Meter type",
      meters: "Meters",
      minHeight: "Min ceiling height",
      minValue: "Min. value",
      minutes: "minutes",
      missingApiKey: "API key is not generated yet",
      missingFormFields: "Please fill the form correctly",
      missingMandatoryField: "Missing mandatory fields",
      mtArea: "MT relevant sqm",
      mtResponsibles: "MT Responsibles",
      name: "Name",
      new: "New",
      newOptionValue: "New value",
      newScenario: "New scenario",
      nextMaintenance: "Next Maintenance",
      no: "No",
      noAppliedFilters: "No applied filters...",
      noAssignedDevice: "No assigned device",
      noBuildingsFound: "There are no buildings for the property",
      noDevice: "There is no device added right now",
      noFloorsFound: "There are no floors for this building.",
      noMatchingOptions: "No matching options!",
      noResults: "The search has no result...",
      noSDChildrenFound: "There are no sub entity on this site design",
      noScenarioAddedCurrently: "No scenarios configured yet.",
      noSubArea: "There are no subareas attached to this area.",
      noViewImage: "There is no available image for this view!",
      notSuccessfulSave: "Save is not successful...",
      note: "Note",
      notes: "Entry notes",
      number: "Number",
      numberCanBeSeated: "Number of seats",
      numberCanBeSeatedHelperText:
        "Calculated data, based on the floor area of ​​the room with a value of 3 square meters/person.",
      numberDedicated: "Number of dedicated seats",
      numberDedicatedHelperText:
        "The number of colleagues assigned to the given property by HR, included in the phone book.",
      numberOfElevators: "Number of elevators",
      numberOfPerson: "Maximum number of persons",
      numberOfPossibleWorkplaces: "Number of possible workplaces",
      numberOfPremises: "Number of premises",
      numberOfStairways: "Number of stairways",
      numberOfWorkplaces: "Number of workplaces",
      numberSeated: "Number of seated persons",
      numberSeatedHelperText: "The number of workstations in the given room.",
      obligation: "Obligation",
      obligations: "Obligations",
      obliged: "Obliged",
      offGrid: "Off Grid",
      open: "Open",
      openableWindow: "Openable windows?",
      operation: "Operation",
      optionAdminEditorLink: "Administration",
      optionEditorLink: "Edit",
      optionEditorPage: "Option Value Editor",
      optionValue: "Option Value",
      optionValueDuplicateTr: "Duplicate translation",
      optionValueTokenEmpty: "Missing token value",
      optionsNumber: "Option Number",
      order: "Order",
      otherRight: "Other right name",
      outdoorUnit: "Outdoor unit?",
      outgoingIPAddress: "Outgoing IP address",
      ownerOfAssets: "Owner of assets",
      ownership: "Ownership",
      parcelNumber: "Parcel number",
      parent: "Parent",
      partner: "Partner",
      partners: "Partners",
      paymentObligations: "Payment obligations",
      performance: "Performance",
      performanceTransfer: "Performance Transfer",
      permission: "Permission",
      permissionDenied: "Permission Denied",
      personnel: "Personnel",
      placement: "Placement",
      populationData: "POPULATION DATA",
      position: "Position",
      postal: "Postal code",
      postalError: "Incorrect postalcode",
      postalRegex:
        "To display the counties and settlements, fill the postal code field!",
      powerProduced: "Power Produced (kW)",
      premise: "Premise",
      premiseNumber: "Premise number",
      premises: "Premises",
      preview: "Preview",
      primary: "Primary",
      privateIndividual: "Private Individual",
      profile: "Profile",
      propAddressError:
        "Filling in the address section is mandatory before saving!",
      propellant: "Propellant",
      properties: "Properties",
      property: "Property",
      propertyManagementModule: "Property Management Module",
      propertyPartnerModeSwitch:
        "Switching between partner modes (Only property related partners or the partners related to any entity what's connected to this property)",
      propertyPartners: "Partners connected to this property",
      propertySheetAppellation: "Name according to the Ownership Sheet",
      protection: "Protection",
      protectionNote: "Protection description",
      publicAreaName: "Public area name",
      publicAreaType: "Public area type",
      publicUtilityType: "Public utility type",
      rain: "Rain: ",
      rank: "Rank",
      readingFrequency: "Reading frequency",
      readings: "Readings",
      regenerateApiKeyConfirmation:
        "Are you sure you want to regenerate the API key?",
      regenerateApiKeyConfirmationCancel: "Cancel",
      regenerateApiKeyConfirmationOk: "confirm",
      reports: "Reports",
      resolution: "Resolution",
      responders: "Scenarios",
      roofType: "Roof type",
      roofing: "Roofing",
      "rvg-automatic-list-refresh-1-min": "Automatic list refresh every minute",
      "rvg-automatic-list-refresh-30-sec":
        "Automatic list refresh every 30 sec",
      "rvg-automatic-list-refresh-5-min": "Automatic list refresh every minute",
      rvg_automatic_list_refresh_1_min: "Automatic list refresh every minute",
      rvg_automatic_list_refresh_30_sec: "Automatic list refresh every 30 sec",
      rvg_automatic_list_refresh_5_min: "Automatic list refresh every minute",
      sap: "SAP Code",
      sapBuildingId: "SAP building identifier",
      save: "Save",
      saveConfirmation: "Do you want to save your modifications?",
      saveError: "Errors occured during save",
      scenarios: "Scenarios",
      search: "Search",
      searchGeneric: "Generic Search",
      searchSiteDesign: "Site Design Search",
      searchZone: "Zone Search",
      searchingDevice: "Searching Device...",
      secondary: "Secondary",
      select: "Select",
      selectInputs: "Select Inputs",
      selectOutput: "Select Output",
      selectTime: "Set start time",
      sendToOrder: "Sent to order",
      sessionExpired: "The session expired, please try to log in again!",
      setPerPremise: "Can Be Set Per Premise",
      settlement: "Settlement",
      sharedUse: "Shared use?",
      siteDesign: "Site design",
      siteDesignPlaceholder: "Assigned NRGmap Site Design",
      slabStructClosing: "Slab structure closing",
      slabStructInter: "Slab structure intermediate",
      slabSubStructClosing: "Slab substructure closing",
      slabSubStructInter: "Slab substructure intermediate",
      solarPanel: "Solar Panel",
      specialData: "Special data",
      sqm: "sqm",
      stairway: "Stairway",
      stairwayId: "Stairway ID",
      stairwayType: "Stairway type",
      startDate: "Start date",
      status: "Status",
      structureTypes: "Structure types",
      subType: "Sub type",
      successfulSave: "Successful save...",
      supplies: "Kitchen supplies",
      syncSites: "Sync",
      system: "System",
      szepaClass: "SZEPA classification",
      tankSize: "Tank Size",
      taxId: "Tax ID",
      taxNumber: "Tax number",
      tcafm: "TCAFM code",
      technicalData: "Technical data",
      temperatureData: "TEMPERATURE DATA",
      temperatureMap: "Temperature Map",
      test: "Test",
      translations: "Translations",
      true: "Yes",
      tui: "TU",
      type: "Type",
      typeGistName: "Please start typing the gist/s name!",
      unauthorizedTenant:
        "The usage of this application is not allowed for your company!",
      updateAggregationGists: "Update Aggregation Gists",
      updateContact: "Edit contact",
      updateLegalSituation: "Edit legal situation",
      updateProperty: "Edit property",
      upload: "Upload",
      uploadDate: "Upload date",
      uploadFile: "Upload File",
      uploader: "Uploader",
      ups: "UPS",
      useOfGeneratedHeat: "Use Of Generated Heat",
      utilization: "Utilization",
      utilizationDeleteConfirm:
        "Are you sure you want to delete this utilization?",
      utilizations: "Utilizations",
      value: "Value",
      windowDepth: "Depth",
      windowHeight: "Height",
      windowMaterial: "Material",
      windowNumber: "Number of windows",
      windowSize: "Size",
      windowWidth: "Width",
      windows: "Windows",
      yearOfManufacture: "Year of manufacture",
      yes: "Yes",
      zoneAdmin: "Zone Admin",
      zoneGroups: "Zone groups",
      zoneMap: "Zone Map",
      zoneName: "The name of the zone",
      zoneNamePlaceholder: "eg. Corridor",
      zoneType: "Zone type",
      noSiteDesign: "Didn't find any attached NRGmap site design.",
      insulationTime: "Time of insulation",
      numberOfSolarPanels: "Number of solar panels",
      performanceOfSolarPanels: "Performance of solar panels"
    },
  },
  es: {
    message: {
      "": "",
      accountNumber: "Número de cuenta",
      accounting: "",
      actions: "Comportamiento",
      adapterConfig: "Configuración del adaptador",
      adapterType: "Tipo de adaptador",
      addDevice: "Agregar dispositivo",
      addLegalSituation: "Nueva situación jurídica",
      addNew: "Agregar nuevo dispositivo",
      addNewAggregation: "Agregar nueva agregación",
      addNewBuilding: "Añadir nuevo edificio",
      addNewPartner: "Añadir Nuevo Socio",
      addNewPartnerType: "Añadir nuevo tipo de socio",
      addNewProperty: "Προσθήκη νέας ιδιοκτησίας",
      addNewSiteDesign: "Añadir nuevo",
      address: "Dirección",
      admin: "Admin",
      aggregationFunction: "Función de agregación",
      aggregationNamePlaceholder: "ej. Nombre de la agregación",
      aggregations: "Agregaciones",
      airConditionerOutdoorUnit: "",
      annualPerformance: "",
      anonymus: "Anónimo",
      areaSize: "",
      areas: "Areas",
      atleastOneCharacter: "Al menos un carácter...",
      back: "Volver",
      backToTheMap: "Volver al mapa",
      baseDatas: "Datos",
      batteryCharge: "Bateria cargada",
      batteryInstallDate: "",
      breakdown: "Desglose",
      building: "Edificio",
      buildings: "Edificios",
      canBePhased: "",
      centralAirConditioner: "",
      centralHeating: "",
      choose: "Elegir",
      chooseLang: "Elegir idioma: ",
      city: "Ciudad",
      classification: "Clasificación",
      close: "Cerrar",
      company: "Empresa",
      confirm: "Confirmar",
      confirmRemove: "¿Está seguro de que desea eliminar este dispositivo?",
      confirmRemoveLS:
        "¿Está seguro de que desea eliminar esta situación legal?",
      confirmRemovePartner:
        "¿Estás seguro de que deseas eliminar a este socio?",
      confirmRemoveScenario:
        "¿Está seguro de que desea eliminar este escenario?",
      contactPhone: "Número de teléfono",
      contacts: "Contactos",
      controlled: "",
      cooling: "",
      coolingHeatingDevice: "",
      copiedToClipboard: "Copiado al portapapeles",
      copy: "Copiar",
      country: "País",
      county: "Condado",
      currentPresenceGist: "Presencia actual",
      data: "Datos",
      date: "Fecha",
      day: "día",
      dedicatedOrgUnit: "",
      delete: "Eliminar",
      deleteConfirm: "",
      deleteLegalSituation: "Eliminar situación legal",
      deleteNotSuccessful: "Eliminación no exitosa",
      deleteSuccessful: "Eliminación exitosa",
      deny: "Negar",
      designation: "Designación",
      device: "Dispositivo",
      deviceMap: "Mapa de dispositivos",
      deviceNamePlaceholder: "ej. Nombre del dispositivo",
      deviceType: "Tipo de dispositivo",
      dieselGenerator: "¿Generador de diesel?",
      dieselGeneratorTitle: "",
      displayName: "Nombre para mostrar",
      district: "Distrito",
      documents: "Documentos",
      door: "Puerta",
      download: "",
      downloadEkrReport: "Descargar informe EKR",
      edit: "",
      editAggregation: "Editar agregación",
      editDevice: "Editar dispositivo",
      editPartner: "Editar Socio",
      editScenario: "Editar escenario",
      editSiteDesign: "Editar",
      editZone: "Editar zona",
      egNumPlaceholder: "ej. 100",
      email: "",
      emailValidationFail: "",
      empty: "",
      energyTradingPartner: "¿Socio comercial de energía?",
      english: "Inglés",
      error: "Error",
      errorAlt: "Una actualización de la página puede resolver el problema",
      expiration: "",
      externalIdentifier: "Identificador Externo",
      false: "",
      fenestration: "",
      fileName: "Nombre",
      fireProtection: "",
      floc: "FLOC ID",
      floor: "Piso",
      floorArea: "Superficie del piso",
      floorSpace: "Espacio del piso",
      floors: "Pisos",
      fuelCellGenerator: "¿Generador de pila de combustible?",
      fuelCellGeneratorTitle: "",
      generateApiKeyButtonTitle: "Nueva clave API",
      generateApiKeyExample: "Ejemplo",
      generateApiKeyTitle: "Clave API",
      globalSiteDesignNotFound:
        "Site design no encontrado, actualice este adaptador.",
      goBack: "Ir a la página anterior",
      gpsCoordinates: "GPS Coordenadas",
      heating: "",
      hour: "hora",
      houseNumber: "Número de casa",
      hungarian: "Húngaro",
      infos: "Informaciones",
      installDate: "",
      invalidMapView: "Los datos del mapa cargado son incorrectos",
      inverterManufacturer: "",
      inverterPerformance: "",
      inverterType: "",
      isActive: "¿Activo?",
      kng: "KING",
      latitude: "Latitud",
      legalRelationship: "Relación jurídica",
      legalRelationshipEnd: "",
      legalRelationshipStart: "",
      legalRelationshipUtilization: "",
      legalSituation: "Situación jurídica",
      legalSituationEnd: "Fin de la situación legal",
      legalSituationStart: "Situación legal de inicio",
      legalSituations: "Situaciones legales",
      levels: "Niveles",
      lidarView: "Vista LIDAR",
      liquidCoolerCoolingPerformance: "",
      liquidCoolerMaxElectricPerformance: "",
      listOfDevices: "Lista de dispositivos",
      loadedAreaHasNoCoordinates:
        "El área cargada no tiene coordenadas geográficas.",
      loading: "Cargando...",
      loginAgain: "Iniciaré sesión nuevamente",
      logoutInProgress: "Cierre de sesión en progreso...",
      longitude: "Longitud",
      mainFunction: "Función principal",
      mainType: "Tipo principal",
      maintainedBy: "",
      mapImage: "Imagen del mapa",
      mapView: "Vista del mapa",
      insulationMaterial: "",
      maxEnergyIntake: "",
      maxValue: "Valor máximo",
      messages: "",
      minValue: "Valor mínimo",
      minutes: "minutos",
      missingApiKey: "La clave API no se ha generado aún",
      missingFormFields: "",
      missingMandatoryField: "",
      mtArea: "MT metro cuadrado relevante",
      mtResponsibles: "MT Responsables",
      name: "Nombre",
      new: "",
      newOptionValue: "Nuevo",
      newScenario: "Nuevo escenario",
      nextMaintenance: "",
      no: "No",
      noAppliedFilters: "No hay filtros aplicados...",
      noAssignedDevice: "No hay dispositivo asignado",
      noBuildingsFound: "No hay edificios para la propiedad.",
      noDevice: "No hay dispositivo agregado actualmente",
      noFloorsFound: "No hay pisos para este edificio.",
      noResults: "La búsqueda no tiene resultados...",
      noSDChildrenFound: "No hay subentidades en este diseño de sitio",
      noScenarioAddedCurrently: "No hay escenarios configurados aún.",
      noSubArea: "No hay subáreas adjuntas a esta área.",
      noViewImage: "¡No hay imagen disponible para esta vista!",
      notSuccessfulSave: "Guardado no exitoso...",
      note: "Nota",
      notes: "Notas de entrada",
      number: "",
      numberCanBeSeated: "Numero de asientos",
      numberDedicated: "Personal dedicado",
      numberOfElevators: "Número de ascensores",
      numberOfPossibleWorkplaces: "Número de posibles lugares de trabajo",
      numberOfStairways: "Número de escaleras",
      numberOfWorkplaces: "Número de lugares de trabajo",
      numberSeated: "Número de sentados",
      offGrid: "",
      open: "",
      optionAdminEditorLink: "Administración",
      optionEditorLink: "Editar",
      optionEditorPage: "Editor de valores de opciones",
      optionValue: "Valores de opciones",
      optionValueDuplicateTr: "",
      optionValueTokenEmpty: "",
      optionsNumber: "Valores de opciones",
      order: "",
      outgoingIPAddress: "Dirección IP de salida",
      ownership: "Posesión",
      parcelNumber: "Número de parcela",
      parent: "",
      partner: "",
      partners: "interlocutores",
      paymentObligations: "Obligaciones de pago",
      performance: "",
      performanceTransfer: "",
      permissionDenied: "Permiso denegado",
      personnel: "",
      populationData: "DATOS DE POBLACIÓN",
      position: "Posición",
      postal: "Postal",
      postalError: "",
      postalRegex:
        "Para mostrar los condados y asentamientos, complete el campo del código postal.",
      powerProduced: "",
      premise: "",
      preview: "",
      privateIndividual: "Persona privada",
      profile: "Perfil",
      propellant: "",
      properties: "Propiedades",
      property: "Propiedad",
      propertyManagementModule: "Módulo de gestión de propiedades",
      propertySheetAppellation: "Nombre según la Hoja de Propiedad",
      protection: "Proteccion",
      protectionNote: "Descripción de la protección",
      publicAreaName: "Nombre del área pública",
      publicAreaType: "Tipo de área pública",
      rain: "Lluvia: ",
      rank: "Rango",
      regenerateApiKeyConfirmation:
        "¿Está seguro de que desea regenerar la clave API?",
      regenerateApiKeyConfirmationCancel: "Cancelar",
      regenerateApiKeyConfirmationOk: "Sí",
      reports: "Informes",
      resolution: "Resolución",
      responders: "Escenarios",
      "rvg-automatic-list-refresh-1-min":
        "Actualización automática de la lista cada minuto",
      "rvg-automatic-list-refresh-30-sec":
        "Actualización automática de la lista cada 30 segundos",
      "rvg-automatic-list-refresh-5-min":
        "Actualización automática de la lista cada 5 minutos",
      rvg_automatic_list_refresh_1_min:
        "Actualización automática de la lista cada minuto",
      rvg_automatic_list_refresh_30_sec:
        "Actualización automática de la lista cada 30 segundos",
      rvg_automatic_list_refresh_5_min:
        "Actualización automática de la lista cada 5 minutos",
      sap: "SAP código",
      save: "Guardar",
      saveError: "Se produjeron errores durante el guardado",
      scenarios: "Escenarios",
      search: "Buscar",
      searchGeneric: "Búsqueda Genérica",
      searchSiteDesign: "Búsqueda de Sitio",
      searchZone: "Búsqueda de Zona",
      searchingDevice: "Buscando dispositivo...",
      select: "Seleccionar",
      selectInputs: "Seleccionar entradas",
      selectOutput: "Seleccionar salida",
      selectTime: "Establecer hora de inicio",
      sendToOrder: "",
      sessionExpired:
        "La sesión ha expirado, ¡por favor intente iniciar sesión nuevamente!",
      setPerPremise: "",
      settlement: "",
      siteDesignPlaceholder: "ej. Nombre del Site Design",
      solarPanel: "¿Panel solar?",
      sqm: "m²",
      stairway: "Escalera",
      status: "Estado",
      structureTypes: "Tipos de estructura",
      subType: "Subtipo",
      successfulSave: "Guardado exitosamente...",
      syncSites: "Sincronizar",
      system: "Sistema",
      tankSize: "",
      taxId: "Identificación del impuesto",
      taxNumber: "Número de identificación fiscal",
      technicalData: "",
      temperatureData: "DATOS DE TEMPERATURA",
      temperatureMap: "Mapa de temperatura",
      test: "Probar",
      translations: "",
      true: "",
      type: "Tipo",
      unauthorizedTenant:
        "¡El uso de esta aplicación no está permitido para su empresa!",
      updateAggregationGists: "Actualizar resúmenes de agregación",
      updateContact: "Editar contacto",
      updateLegalSituation: "Editar situación legal",
      updateProperty: "Editar propiedad",
      upload: "Subir",
      uploadDate: "Fecha de carga",
      uploadFile: "Subir archivo",
      uploader: "Cargador",
      ups: "",
      useOfGeneratedHeat: "",
      value: "Valor",
      yes: "Sí",
      zoneAdmin: "Administrador de zonas",
      zoneGroups: "Grupos de zonas",
      zoneMap: "Mapa de zonas",
      zoneName: "Nombre de la zona",
      zoneNamePlaceholder: "ej. Pasillo",
      zoneType: "Tipo de zona",
    },
  },
  el: {
    message: {
      "": "",
      accountNumber: "Αριθμός λογαριασμού",
      accounting: "",
      actions: "δράση",
      adapterConfig: "Διαμόρφωση προσαρμογέα",
      adapterType: "Τύπος προσαρμογέα",
      addDevice: "Προσθήκη Συσκευής",
      addLegalSituation: "Νέα νομική κατάσταση",
      addNew: "Προσθήκη νέας συσκευής",
      addNewAggregation: "Προσθήκη νέας Συγκέντρωσης",
      addNewBuilding: "Προσθήκη νέου κτιρίου",
      addNewPartner: "Προσθέστε νέο συνεργάτη",
      addNewPartnerType: "Προσθέστε νέο τύπο συνεργάτη",
      addNewProperty: "Agregar nueva propiedad",
      addNewSiteDesign: "Προσθήκη νέου",
      address: "Διεύθυνση",
      admin: "Διαχειριστής",
      aggregationFunction: "Συνάρτηση συγκέντρωσης",
      aggregationNamePlaceholder: "π.χ. Όνομα Συγκέντρωσης",
      aggregations: "Συγκεντρώσεις",
      airConditionerOutdoorUnit: "",
      annualPerformance: "",
      anonymus: "Ανώνυμος",
      areaSize: "",
      areas: "Περιοχές",
      atleastOneCharacter: "Τουλάχιστον ένας χαρακτήρας...",
      back: "πίσω",
      backToTheMap: "Επιστροφή στον χάρτη",
      baseDatas: "Δεδομένα",
      batteryCharge: "Φόρτιση μπαταρίας",
      batteryInstallDate: "",
      breakdown: "Ανάλυση",
      building: "Κτίριο",
      buildings: "κτίρια",
      canBePhased: "",
      centralAirConditioner: "",
      centralHeating: "",
      choose: "Επιλέξτε",
      chooseLang: "Επιλογή Γλώσσας: ",
      city: "Πόλη",
      classification: "Ταξινόμηση",
      close: "Κλείσιμο",
      company: "εταιρεία",
      confirm: "Επιβεβαίωση",
      confirmRemove: "Είστε βέβαιοι ότι θέλετε να αφαιρέσετε αυτή τη συσκευή;",
      confirmRemoveLS:
        "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν τη νομική κατάσταση;",
      confirmRemovePartner:
        "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον συνεργάτη;",
      confirmRemoveScenario:
        "Είστε βέβαιοι ότι θέλετε να αφαιρέσετε αυτό το σενάριο;",
      contactPhone: "Αριθμός τηλεφώνου",
      contacts: "επαφές",
      controlled: "",
      cooling: "",
      coolingHeatingDevice: "",
      copiedToClipboard: "Αντιγράφηκε στο πρόχειρο",
      copy: "Αντιγραφή",
      country: "Χώρα",
      county: "Κομητεία",
      currentPresenceGist: "Παρούσα κατάσταση",
      data: "Δεδομένα",
      date: "ημερομηνία",
      day: "ημέρα",
      dedicatedOrgUnit: "",
      delete: "Διαγραφή",
      deleteConfirm: "",
      deleteLegalSituation: "Διαγραφή νομικής κατάστασης",
      deleteNotSuccessful: "Η διαγραφή δεν ήταν επιτυχής",
      deleteSuccessful: "Η διαγραφή ήταν επιτυχής",
      deny: "Άρνηση",
      designation: "Ονομασία",
      device: "Συσκευή",
      deviceMap: "Χάρτης Συσκευών",
      deviceNamePlaceholder: "π.χ. Όνομα Συσκευής",
      deviceType: "Τύπος Συσκευής",
      dieselGenerator: "Γεννήτρια ντίζελ;",
      dieselGeneratorTitle: "",
      displayName: "Ονομασία",
      district: "Περιοχή",
      documents: "Έγγραφα",
      door: "Πόρτα",
      download: "",
      downloadEkrReport: "Λήψη αναφοράς EKR",
      edit: "",
      editAggregation: "Επεξεργασία Συγκέντρωσης",
      editDevice: "Επεξεργασία Συσκευής",
      editPartner: "Επεξεργασία συνεργάτη",
      editScenario: "Επεξεργασία Σενάριο",
      editSiteDesign: "Επεξεργασία",
      editZone: "Επεξεργασία ζώνης",
      egNumPlaceholder: "π.χ. 100",
      email: "",
      emailValidationFail: "",
      empty: "",
      energyTradingPartner: "Συνεργάτης Εμπορίου Ενέργειας;",
      english: "Αγγλικά",
      error: "Σφάλμα",
      errorAlt: "Η ανανέωση της σελίδας μπορεί να λύσει το πρόβλημα",
      expiration: "",
      externalIdentifier: "Εξωτερικός Αναγνωριστικός Κωδικός",
      false: "",
      fenestration: "",
      fileName: "Όνομα_αρχείου",
      fireProtection: "",
      floc: "Αναγνωριστικό FLOC",
      floor: "Πάτωμα",
      floorArea: "Περιοχή ορόφου",
      floorSpace: "Χώρος ορόφου",
      floors: "Όροφοι",
      fuelCellGenerator: "Γεννήτρια κυψελών καυσίμου;",
      fuelCellGeneratorTitle: "",
      generateApiKeyButtonTitle: "Νέο κλειδί API",
      generateApiKeyExample: "Παράδειγμα",
      generateApiKeyTitle: "Κλειδί API",
      globalSiteDesignNotFound:
        "Το site design δεν βρέθηκε, ενημερώστε αυτόν τον προσαρμογέα.",
      goBack: "Επιστροφή στην προηγούμενη σελίδα",
      gpsCoordinates: "Συντεταγμένες GPS",
      heating: "",
      hour: "ώρα",
      houseNumber: "Αριθμός κατοικίας",
      hungarian: "Ουγγρικά",
      infos: "Πληροφορίες",
      installDate: "",
      invalidMapView: "Τα δεδομένα του φορτωμένου χάρτη είναι εσφαλμένα",
      inverterManufacturer: "",
      inverterPerformance: "",
      inverterType: "",
      isActive: "Ενεργό;",
      kng: "KING",
      latitude: "Γεωγραφικό πλάτος",
      legalRelationship: "Νομική σχέση",
      legalRelationshipEnd: "",
      legalRelationshipStart: "",
      legalRelationshipUtilization: "",
      legalSituation: "Νομική κατάσταση",
      legalSituationEnd: "Τέλος νομικής κατάστασης",
      legalSituationStart: "Έναρξη νομικής κατάστασης",
      legalSituations: "Νομικές καταστάσεις",
      levels: "Επίπεδα",
      lidarView: "Προβολή LIDAR",
      liquidCoolerCoolingPerformance: "",
      liquidCoolerMaxElectricPerformance: "",
      listOfDevices: "Λίστα Συσκευών",
      loadedAreaHasNoCoordinates:
        "Η φορτωμένη περιοχή δεν έχει γεωγραφικές συντεταγμένες.",
      loading: "Φόρτωση...",
      loginAgain: "Θα συνδεθώ ξανά",
      logoutInProgress: "Αποσύνδεση σε εξέλιξη...",
      longitude: "Γεωγραφικό μήκος",
      mainFunction: "Κύρια λειτουργία",
      mainType: "Κύριος τύπος",
      maintainedBy: "",
      mapImage: "Εικόνα χάρτη",
      mapView: "προβολή χάρτη",
      insulationMaterial: "",
      maxEnergyIntake: "",
      maxValue: "Μέγιστη αξία",
      messages: "",
      minValue: "Ελάχιστο αξία",
      minutes: "λεπτά",
      missingApiKey: "Το κλειδί API δεν έχει δημιουργηθεί ακόμη",
      missingFormFields: "",
      missingMandatoryField: "",
      mtArea: "ΜΤ σχετικό τ.μ",
      mtResponsibles: "MT Υπεύθυνοι",
      name: "Όνομα",
      new: "",
      newOptionValue: "νέα αξία",
      newScenario: "Νέο Σενάριο",
      nextMaintenance: "",
      no: "όχι",
      noAppliedFilters: "Δεν εφαρμόζονται φίλτρα...",
      noAssignedDevice: "Δεν έχει ανατεθεί συσκευή",
      noBuildingsFound: "Δεν υπάρχουν κτίρια για το ακίνητο",
      noDevice: "Δεν έχει προστεθεί συσκευή αυτή τη στιγμή",
      noFloorsFound: "Δεν βρέθηκαν όροφοι για αυτό το κτίριο.",
      noResults: "Η αναζήτηση δεν έχει αποτελέσματα...",
      noSDChildrenFound:
        "Δεν υπάρχουν υπο-περιοχές σε αυτό το σχέδιο τοποθεσίας",
      noScenarioAddedCurrently: "Δεν έχουν διαμορφωθεί ακόμη σενάρια.",
      noSubArea: "Δεν υπάρχουν υποπεριοχές συνδεδεμένες με αυτήν την περιοχή.",
      noViewImage: "Δεν υπάρχει διαθέσιμη εικόνα για αυτή την προβολή!",
      notSuccessfulSave: "Η αποθήκευση δεν ήταν επιτυχής...",
      note: "Σημείωση",
      notes: "Σημειώσεις εισόδου",
      number: "",
      numberCanBeSeated: "αριθμός θέσεων",
      numberDedicated: "Αφοσιωμένο προσωπικό",
      numberOfElevators: "Αριθμός Ανελκυστήρων",
      numberOfPossibleWorkplaces: "Αριθμός πιθανών θέσεων εργασίας",
      numberOfStairways: "Αριθμός κλιμακοστασίων",
      numberOfWorkplaces: "Αριθμός θέσεων εργασίας",
      numberSeated: "Αριθμός καθισμένων",
      offGrid: "",
      open: "",
      optionAdminEditorLink: "διαχείριση",
      optionEditorLink: "Επεξεργασία",
      optionEditorPage: "Σελίδα επεξεργασίας επιλογών",
      optionValue: "Timí epilogís",
      optionValueDuplicateTr: "",
      optionValueTokenEmpty: "",
      optionsNumber: "αριθμός επιλογών",
      order: "",
      outgoingIPAddress: "Εξερχόμενη διεύθυνση IP",
      ownership: "Ιδιοκτησία",
      parcelNumber: "Αριθμός δέματος",
      parent: "",
      partner: "",
      partners: "Συνεργάτες",
      paymentObligations: "Υποχρεώσεις πληρωμής",
      performance: "",
      performanceTransfer: "",
      permissionDenied: "Άρνηση πρόσβασης",
      personnel: "",
      populationData: "ΔΕΔΟΜΕΝΑ ΠΛΗΘΥΣΜΟΥ",
      position: "Θέση",
      postal: "Ταχυδρομικός",
      postalError: "",
      postalRegex:
        "Για να εμφανίσετε τους νομούς και τους οικισμούς, συμπληρώστε το πεδίο ταχυδρομικός κώδικας!",
      powerProduced: "",
      premise: "",
      preview: "",
      privateIndividual: "άτομο",
      profile: "Προφίλ",
      propellant: "",
      properties: "Ιδιότητες",
      property: "Ιδιοκτησία",
      propertyManagementModule: "Ενότητα διαχείρισης ακινήτων",
      propertySheetAppellation: "Όνομα σύμφωνα με το Φύλλο Ιδιοκτησίας",
      protection: "ΠΡΟΣΤΑΣΙΑ",
      protectionNote: "Προστατευτικό σημείωμα",
      publicAreaName: "Όνομα δημόσιου χώρου",
      publicAreaType: "Τύπος κοινόχρηστου χώρου",
      rain: "Βροχή: ",
      rank: "Βαθμός",
      regenerateApiKeyConfirmation:
        "Είστε σίγουροι ότι θέλετε να αναγεννήσετε το κλειδί API;",
      regenerateApiKeyConfirmationCancel: "Ακύρωση",
      regenerateApiKeyConfirmationOk: "Ναι",
      reports: "Αναφορές",
      resolution: "Ανάλυση",
      responders: "Σενάρια",
      "rvg-automatic-list-refresh-1-min": "Αυτόματη ανανέωση λίστας κάθε λεπτό",
      "rvg-automatic-list-refresh-30-sec":
        "Αυτόματη ανανέωση λίστας κάθε 30 δευτερόλεπτα",
      "rvg-automatic-list-refresh-5-min":
        "Αυτόματη ανανέωση λίστας κάθε 5 λεπτά",
      rvg_automatic_list_refresh_1_min: "Αυτόματη ανανέωση λίστας κάθε λεπτό",
      rvg_automatic_list_refresh_30_sec:
        "Αυτόματη ανανέωση λίστας κάθε 30 δευτερόλεπτα",
      rvg_automatic_list_refresh_5_min: "Αυτόματη ανανέωση λίστας κάθε 5 λεπτά",
      sap: "Κωδικός SAP",
      save: "Αποθήκευση",
      saveError: "Παρουσιάστηκαν σφάλματα κατά την αποθήκευση",
      scenarios: "Σενάρια",
      search: "Αναζήτηση",
      searchGeneric: "γενική αναζήτηση",
      searchSiteDesign: "αναζήτηση ιστότοπου",
      searchZone: "αναζήτηση ζώνης",
      searchingDevice: "Αναζήτηση συσκευής...",
      select: "Επιλογή",
      selectInputs: "Επιλογή Εισόδων",
      selectOutput: "Επιλογή Εξόδου",
      selectTime: "Ρύθμιση ώρας έναρξης",
      sendToOrder: "",
      sessionExpired:
        "Η συνεδρία έληξε, παρακαλώ προσπαθήστε να συνδεθείτε ξανά!",
      setPerPremise: "",
      settlement: "",
      siteDesignPlaceholder: "π.χ. Όνομα Site Design",
      solarPanel: "Ηλιακά πάνελ;",
      sqm: "τ.μ.",
      stairway: "Σκάλα",
      status: "Κατάσταση",
      structureTypes: "Τύποι δομών",
      subType: "Δευτερεύων τύπος",
      successfulSave: "Η αποθήκευση ήταν επιτυχής...",
      syncSites: "Συγχρονισμός",
      system: "Σύστημα",
      tankSize: "",
      taxId: "τον αριθμό φορολογικού μητρώου",
      taxNumber: "Αριθμός φορολογικού μητρώου",
      technicalData: "",
      temperatureData: "ΔΕΔΟΜΕΝΑ ΘΕΡΜΟΚΡΑΣΙΑΣ",
      temperatureMap: "Χάρτης Θερμοκρασίας",
      test: "Δοκιμή",
      translations: "",
      true: "",
      type: "τύπος",
      unauthorizedTenant:
        "Η χρήση αυτής της εφαρμογής δεν επιτρέπεται για την εταιρεία σας!",
      updateAggregationGists: "Ενημέρωση Συγκέντρωσης Gists",
      updateContact: "Επεξεργασία επαφής",
      updateLegalSituation: "Επεξεργασία Νομική κατάσταση",
      updateProperty: "Επεξεργασία ιδιοκτησίας",
      upload: "Μεταφόρτωση",
      uploadDate: "Ημερομηνία μεταφόρτωσης",
      uploadFile: "Ανέβασμα αρχείου",
      uploader: "Μεταφορτωτής",
      ups: "",
      useOfGeneratedHeat: "",
      value: "αξία",
      yes: "Ναί",
      zoneAdmin: "Διαχειριστής Ζωνών",
      zoneGroups: "Ομάδες ζωνών",
      zoneMap: "Χάρτης Ζωνών",
      zoneName: "Το όνομα της ζώνης",
      zoneNamePlaceholder: "π.χ. Διάδρομος",
      zoneType: "Τύπος ζώνης",
    },
  },
  de: {
    message: {
      "": "",
      accountNumber: "ΑKontonummer",
      accounting: "",
      actions: "Aktionen",
      adapterConfig: "Adapterkonfiguration",
      adapterType: "Adaptertyp",
      addDevice: "Gerät hinzufügen",
      addLegalSituation: "Neue Rechtslage",
      addNew: "Neues Gerät hinzufügen",
      addNewAggregation: "Neue Aggregation hinzufügen",
      addNewBuilding: "Neues Gebäude hinzufügen",
      addNewPartner: "Neuen Partner hinzufügen",
      addNewPartnerType: "Neuer Partner hinzufügen",
      addNewProperty: "Neue Eigenschaft hinzufügen",
      addNewSiteDesign: "Neu hinzufügen",
      address: "Adresse",
      admin: "Admin",
      aggregationFunction: "Aggregationsfunktion",
      aggregationNamePlaceholder: "z.B. Aggregatname",
      aggregations: "Aggregationen",
      airConditionerOutdoorUnit: "",
      annualPerformance: "",
      anonymus: "Anonym",
      areaSize: "",
      areas: "Bereiche",
      atleastOneCharacter: "Mindestens ein Zeichen...",
      back: "Zurück",
      backToTheMap: "Zurück zur Karte",
      baseDatas: "Daten",
      batteryCharge: "Akku-Ladung",
      batteryInstallDate: "",
      breakdown: "Aufschlüsselung",
      building: "Gebäude",
      buildings: "Gebäude",
      canBePhased: "",
      centralAirConditioner: "",
      centralHeating: "",
      choose: "Wählen",
      chooseLang: "Sprache wählen: ",
      city: "Stadt",
      classification: "Einstufung",
      close: "Schließen",
      company: "Unternehmen",
      confirm: "Bestätigen",
      confirmRemove:
        "Sind Sie sicher, dass Sie dieses Gerät entfernen möchten?",
      confirmRemoveLS: "Möchten Sie diese Rechtslage wirklich löschen?",
      confirmRemovePartner: "Möchten Sie diesen Partner wirklich löschen?",
      confirmRemoveScenario:
        "Sind Sie sicher, dass Sie dieses Szenario entfernen möchten?",
      contactPhone: "Telefonnummer",
      contacts: "Kontakte",
      controlled: "",
      cooling: "",
      coolingHeatingDevice: "",
      copiedToClipboard: "In die Zwischenablage kopiert",
      copy: "Kopieren",
      country: "Land",
      county: "Bezirk",
      currentPresenceGist: "Aktuelle Anwesenheit",
      data: "Daten",
      date: "Datum",
      day: "Tag",
      dedicatedOrgUnit: "",
      delete: "Löschen",
      deleteConfirm: "",
      deleteLegalSituation: "Rechtslage löschen",
      deleteNotSuccessful: "Löschen war nicht erfolgreich",
      deleteSuccessful: "Löschen erfolgreich",
      deny: "Ablehnen",
      designation: "Bezeichnung",
      device: "Gerät",
      deviceMap: "Gerätekarte",
      deviceNamePlaceholder: "z.B. Gerätename",
      deviceType: "Gerätetyp",
      dieselGenerator: "Dieselgenerator?",
      dieselGeneratorTitle: "",
      displayName: "Anzeigename",
      district: "Bezirk",
      documents: "Dokumente",
      door: "Tür",
      download: "",
      downloadEkrReport: "EKR-Bericht herunterladen",
      edit: "",
      editAggregation: "Aggregation bearbeiten",
      editDevice: "Gerät bearbeiten",
      editPartner: "Partner bearbeiten",
      editScenario: "Szenario bearbeiten",
      editSiteDesign: "Bearbeiten",
      editZone: "Zone bearbeiten",
      egNumPlaceholder: "z.B. 100",
      email: "",
      emailValidationFail: "",
      empty: "",
      energyTradingPartner: "Energiehandelspartner?",
      english: "Englisch",
      error: "Fehler",
      errorAlt: "Ein Neuladen der Seite könnte das Problem lösen",
      expiration: "",
      externalIdentifier: "Externe Kennung",
      false: "",
      fenestration: "",
      fileName: "Dateiname",
      fireProtection: "",
      floc: "FLOC-Nummer",
      floor: "Boden",
      floorArea: "Wohnfläche",
      floorSpace: "Grundfläche",
      floors: "Etagen",
      fuelCellGenerator: "Brennstoffzellengenerator?",
      fuelCellGeneratorTitle: "",
      generateApiKeyButtonTitle: "Neuer API-Schlüssel",
      generateApiKeyExample: "Beispiel",
      generateApiKeyTitle: "API-Schlüssel",
      globalSiteDesignNotFound:
        "Site Design nicht gefunden, aktualisieren Sie diesen Adapter.",
      goBack: "Zur vorherigen Seite gehen",
      gpsCoordinates: "GPS Koordinaten",
      heating: "",
      hour: "Stunde",
      houseNumber: "Hausnummer",
      hungarian: "Ungarisch",
      infos: "Informationen",
      installDate: "",
      invalidMapView: "Die Daten der geladenen Karte sind inkorrekt",
      inverterManufacturer: "",
      inverterPerformance: "",
      inverterType: "",
      isActive: "Aktiv?",
      kng: "KING",
      latitude: "Breitengrad",
      legalRelationship: "Rechtsverhältnis",
      legalRelationshipEnd: "",
      legalRelationshipStart: "",
      legalRelationshipUtilization: "",
      legalSituation: "Rechtliche Situation",
      legalSituationEnd: "Rechtslage Ende",
      legalSituationStart: "Rechtslage Beginn",
      legalSituations: "Rechtliche Situationen",
      levels: "Ebenen",
      lidarView: "LIDAR-Ansicht",
      liquidCoolerCoolingPerformance: "",
      liquidCoolerMaxElectricPerformance: "",
      listOfDevices: "Liste der Geräte",
      loadedAreaHasNoCoordinates:
        "Das geladene Gebiet hat keine geografischen Koordinaten.",
      loading: "Wird geladen...",
      loginAgain: "Ich werde mich erneut anmelden",
      logoutInProgress: "Abmeldung läuft...",
      longitude: "Längengrad",
      mainFunction: "Hauptfunktion",
      mainType: "Haupttyp",
      maintainedBy: "",
      mapImage: "Kartenbild",
      mapView: "Kartenansicht",
      insulationMaterial: "",
      maxEnergyIntake: "",
      maxValue: "Maximalwert",
      messages: "",
      minValue: "Mindestwert",
      minutes: "Minuten",
      missingApiKey: "API-Schlüssel ist noch nicht generiert",
      missingFormFields: "",
      missingMandatoryField: "",
      mtArea: "MT relevanter Quadratmeter",
      mtResponsibles: "MT Verantwortliche",
      name: "Name",
      new: "",
      newOptionValue: "Neuer Wert",
      newScenario: "Neues Szenario",
      nextMaintenance: "",
      no: "NEIN",
      noAppliedFilters: "Keine Filter angewendet...",
      noAssignedDevice: "Kein zugewiesenes Gerät",
      noBuildingsFound: "Es gibt keine Gebäude für das Grundstück",
      noDevice: "Derzeit ist kein Gerät hinzugefügt",
      noFloorsFound: "Keine Etagen für dieses Gebäude gefunden.",
      noResults: "Die Suche hat keine Ergebnisse...",
      noSDChildrenFound:
        "Es gibt keine Unterentitäten in diesem Standortdesign",
      noScenarioAddedCurrently: "Derzeit sind keine Szenarien konfiguriert.",
      noSubArea: "Es sind keine Unterbereiche an diesem Bereich angehängt.",
      noViewImage: "Es ist kein Bild für diese Ansicht verfügbar!",
      notSuccessfulSave: "Speichern nicht erfolgreich...",
      note: "Anmerkung",
      notes: "Eintragshinweise",
      number: "",
      numberCanBeSeated: "Anzahl Sitzplätze",
      numberDedicated: "Engagiertes Personal",
      numberOfElevators: "Anzahl der Aufzüge",
      numberOfPossibleWorkplaces: "Anzahl der möglichen Arbeitsplätze",
      numberOfStairways: "Anzahl der Treppen",
      numberOfWorkplaces: "Anzahl der Arbeitsplätze",
      numberSeated: "Anzahl der Sitzplätze",
      offGrid: "",
      open: "",
      optionAdminEditorLink: "Verwaltung",
      optionEditorLink: "Bearbeiten",
      optionEditorPage: "Optionseditorseite",
      optionValue: "Optionswert",
      optionValueDuplicateTr: "",
      optionValueTokenEmpty: "",
      optionsNumber: "Anzahl der Optionen",
      order: "",
      outgoingIPAddress: "Ausgehende IP-Adresse",
      ownership: "Eigentum",
      parcelNumber: "Paketnummer",
      parent: "",
      partner: "",
      partners: "Partner",
      paymentObligations: "Zahlungsverpflichtungen",
      performance: "",
      performanceTransfer: "",
      permissionDenied: "Zugriff verweigert",
      personnel: "",
      populationData: "BEVÖLKERUNGSDATEN",
      position: "Position",
      postal: "Postleitzahl",
      postalError: "",
      postalRegex:
        "Zur Anzeige der Landkreise und Ortschaften füllen Sie bitte das PLZ-Feld aus!",
      powerProduced: "",
      premise: "",
      preview: "",
      privateIndividual: "Privatperson",
      profile: "Profil",
      propellant: "",
      properties: "Eigenschaften",
      property: "Eigentum",
      propertyManagementModule: "Modul Immobilienverwaltung",
      propertySheetAppellation: "Name laut Eigentumsverzeichnis",
      protection: "Schutz",
      protectionNote: "Schutzbeschreibung",
      publicAreaName: "Name des öffentlichen Bereichs",
      publicAreaType: "Art des öffentlichen Bereichs",
      rain: "Regen: ",
      rank: "Rang",
      regenerateApiKeyConfirmation:
        "Sind Sie sicher, dass Sie den API-Schlüssel regenerieren möchten?",
      regenerateApiKeyConfirmationCancel: "Abbrechen",
      regenerateApiKeyConfirmationOk: "Ja",
      reports: "Berichte",
      resolution: "Auflösung",
      responders: "Szenarien",
      "rvg-automatic-list-refresh-1-min":
        "Automatische Listenaktualisierung jede Minute",
      "rvg-automatic-list-refresh-30-sec":
        "Automatische Listenaktualisierung alle 30 Sekunden",
      "rvg-automatic-list-refresh-5-min":
        "Automatische Listenaktualisierung alle 5 Minuten",
      rvg_automatic_list_refresh_1_min:
        "Automatische Listenaktualisierung jede Minute",
      rvg_automatic_list_refresh_30_sec:
        "Automatische Listenaktualisierung alle 30 Sekunden",
      rvg_automatic_list_refresh_5_min:
        "Automatische Listenaktualisierung alle 5 Minuten",
      sap: "SAP-Code",
      save: "Speichern",
      saveError: "Fehler beim Speichern aufgetreten",
      scenarios: "Szenarien",
      search: "Suchen",
      searchGeneric: "Generische Suche",
      searchSiteDesign: "Seitensuche",
      searchZone: "Zonensuche",
      searchingDevice: "Gerät wird gesucht...",
      select: "Auswählen",
      selectInputs: "Eingaben auswählen",
      selectOutput: "Ausgabe auswählen",
      selectTime: "Startzeit festlegen",
      sendToOrder: "",
      sessionExpired:
        "Die Sitzung ist abgelaufen, bitte versuchen Sie erneut, sich anzumelden!",
      setPerPremise: "",
      settlement: "",
      siteDesignPlaceholder: "z.B. Site Design Name",
      solarPanel: "Sonnenkollektor?",
      sqm: "qm",
      stairway: "Treppe",
      status: "Status",
      structureTypes: "Strukturtypen",
      subType: "Subtyp",
      successfulSave: "Erfolgreich gespeichert...",
      syncSites: "Synchronisieren",
      system: "System",
      tankSize: "",
      taxId: "Steuer ID",
      taxNumber: "Steuernummer",
      technicalData: "",
      temperatureData: "TEMPERATURDATEN",
      temperatureMap: "Temperaturkarte",
      test: "Testen",
      translations: "",
      true: "",
      type: "Typ",
      unauthorizedTenant:
        "Die Nutzung dieser Anwendung ist für Ihr Unternehmen nicht gestattet!",
      updateAggregationGists: "Aggregations-Gists aktualisieren",
      updateContact: "Kontakt bearbeiten",
      updateLegalSituation: "Rechtliche Situation bearbeiten",
      updateProperty: "Eigenschaft bearbeiten",
      upload: "Hochladen",
      uploadDate: "Datum des Hochladens",
      uploadFile: "Datei hochladen",
      uploader: "Hochladen",
      ups: "",
      useOfGeneratedHeat: "",
      value: "Wert",
      yes: "Ja",
      zoneAdmin: "Zonen-Admin",
      zoneGroups: "Zonengruppen",
      zoneMap: "Zonenkarten",
      zoneName: "Zonenname",
      zoneNamePlaceholder: "z.B. Korridor",
      zoneType: "Zonentyp",
    },
  },
};
export default messages;
