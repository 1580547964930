<template>
    <label class="label mt-0 pt-0" for="">
            <span class="label-text-alt flex flex-row">{{ title }}
                <span v-if="mandatory" :class="{'im-mandatory': inputValue == '' && mandatory == true}">{{ " *" }}</span>
                <SVGRenderer v-if="helperText" svgKey="help_icon" class="w-4 h-4 pl-2 tooltip tooltip-right"
                    :data-tip="helperText" />
                
            </span>
            
        </label>
</template>

<script setup>
import SVGRenderer from '../../SVGRenderer.vue';
import {defineModel } from 'vue';

defineProps(['title', 'helperText', 'mandatory'])
const inputValue = defineModel()

</script>

<style scoped>
.im-mandatory{
    color: red;
}
</style>