<template>
    <div>

        <span v-if="inputError != ''" class="label-text-alt text-red-400">{{ $t(inputError) }}</span>
        <!-- eslint-disable -->
        <StandardInput v-if="!notStandardInputs.includes(config.type)" :type="config.type"
            v-model:inputValue="inputValue" v-model:focus="focus"></StandardInput>


        <div v-if="config.type == 'optionSearchEndpoint'">
            <SearchOptions :type="config.optionSearchEndpoint" v-model="inputValue" />
        </div>

        <div v-if="config.type == 'option' || config.type == 'optionapi' || config.type == 'optionManual'">
            <RemOption v-model:inputValue="inputValue" v-model:parent="parent" v-model:validated="validated"
                v-model:inputError="inputError" v-model:focus="focus" :type="config.type"
                :valueLabel="config.valueLabel" :optionValue="config.optionValue" :endPoint="config.endPoint"
                :manualOptions="config.manualOptions" :displayValue="config.displayValue"
                :searchable="config.searchable" :onchangeMethod="config.onchangeMethod"
                :validationMethod="config.validationMethod" :mandatory="config.mandatory">
            </RemOption>
        </div>

        <RemCheckBox v-if="config.type == 'checkbox'" :type="config.type" v-model:inputValue="inputValue" v-model:focus="focus"></RemCheckBox>

        <input v-if="config.type == 'toggle'" v-model="inputValue" type="checkbox" class="toggle toggle-secondary"
            checked="checked" @focusout="focus = false" />
        <textarea class="w-full" v-if="config.type == 'textarea'" :id="`${title}${type}`" v-model="inputValue"
            @focusout="focus = false"></textarea>
        <!-- eslint-enable -->
    </div>
</template>

<script setup>
import StandardInput from './StandardInput.vue';
import RemCheckBox from './RemCheckBox.vue';
import RemOption from './RemOption.vue';
import SearchOptions from '../../SearchOptions.vue'


import { ref, defineModel, watch } from 'vue';



const props = defineProps(['config']);
const inputValue = defineModel('inputValue');
const parent = defineModel('parent')
const validated = defineModel('validated')
const focus = defineModel('focus')

const inputError = ref("");

const notStandardInputs = ['option', 'optionapi', 'checkbox', 'label', 'toggle', 'optionManual', 'optionSearchEndpoint']

if (props.config.mandatory && (inputValue.value == "" || inputValue.value == null)) {
    validated.value = false;
}
if (props.config.mandatory && props.config.validationMethod && (inputValue.value == "" || inputValue.value == null)) {
    validated.value = false;
}

if (props.config.type != 'option' && props.config.type != 'optionapi' && props.config.type != 'optionManual')
    watch(() => inputValue.value, (newValue, oldValue) => {


        if (props.config.mandatory && inputValue.value == "") {
            validated.value = false

        } else {
            validated.value = true;

        }

        if (props.config.validationMethod && newValue != '' && newValue != oldValue) {
            props.config.validationMethod([], [], "", newValue, oldValue, inputValue, inputError)
            if (inputError.value == "") {
                validated.value = true
            } else {
                validated.value = false
            }
        }


    })

</script>